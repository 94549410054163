import { CompromisoEstudiante } from "./CompromisoEstudiante";

export class Compromiso {
  CompromisoId: number;
  CursoId: number;
  Descripcion: string;
  Estado: boolean;
  Estudiante: CompromisoEstudiante;
  EsBloqueado: boolean;

  constructor(C) {
    if (C) {
      this.CompromisoId = C.CompromisoId;
      this.Descripcion = C.Descripcion;
      this.Estado = C.Estado;
      this.EsBloqueado = C.EsBloqueado;
      if (C.Estudiante) {
        this.Estudiante = new CompromisoEstudiante(C.Estudiante);
      }
    }
  }
}
