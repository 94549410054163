

import { Injectable } from '@angular/core';
import { ApiHttpService } from 'src/app/services/api-http/ApiHttp.service';
import { Observable } from 'rxjs-compat/Observable';
import { map, catchError } from 'rxjs/operators';

import { LoginDialogComponent } from 'src/app/components/auth/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { SesionService } from '../Sesion/Sesion.service';




@Injectable({
  providedIn: 'root'
})
export class SeguridadService {

  public Dominio = 'Seguridad';
  private DialogLogin;
  public DialogLoginOpen = false;
  private _ip: string = '0.0.0.0';

  constructor(
    private httpClient: HttpClient,
    private handler: HttpBackend,
    private API: ApiHttpService,
    private dialog: MatDialog,
    private SesionSVC: SesionService
  ) {
    this.httpClient = new HttpClient(handler);
  }

  /*************************** Sesion ******************************/
  public IniciarSesion(data, imperative): Observable<any> {
    /*
    return this.API.POST(this.Dominio, 'CrearSesion', {}, Headers);
    */

    let _HttpOptions = {
      headers: new HttpHeaders({
        NombreUsuario: data.usuario,
        Password: data.password,
        Imperative: imperative.toString(),
        OrganizacionId: '1',
        SistemaId: data.SistemaId.toString(),
        //ClienteIp: this._ip
      }),
    }
    this.httpClient = new HttpClient(this.handler);
    return this.httpClient.post(this.API.GetUrlApiEndpoint(this.Dominio, 'CrearSesion'),data,_HttpOptions).pipe(
      map(response => this.API.SuccessCallback(response)),
      catchError(error => this.API.ErrorCallback(error))
    );
  }
  public CrearSesionSSO(_NombreUsuario: string, _Password: string, _SistemaId: number): Observable<any> {
    console.log(_SistemaId);
    let _HttpOptions = {
      headers: new HttpHeaders({
        NombreUsuario: _NombreUsuario,
        Password: _Password,
        SistemaId: _SistemaId.toString(),
        //ClienteIp: this._ip
      }),
    }
    this.httpClient = new HttpClient(this.handler);
    return this.httpClient.post(this.API.GetUrlApiEndpoint(this.Dominio, 'CrearSesionSSO'),{},_HttpOptions).pipe(
      map(response => this.API.SuccessCallback(response)),
      catchError(error => this.API.ErrorCallback(error))
    );
  }

  public ReactivarSesion(data, imperative): Observable<any>  {
    const Headers = [
      {'NombreUsuario': data.usuario},
      {'Password': data.password},
      {'Imperative': imperative},
      {'OrganizacionId': '1'},
      {'SistemaId': data.SistemaId}
    ];
    return this.API.POST(this.Dominio, 'ReactivarSesion',{}, Headers);
  }

  public ExtenderSesion(Token) {
    const Headers = [
      {
        'Token': Token,
        'SistemaId': this.SesionSVC.SesionStorage.Sesion.SistemaId
      }
    ]
    return this.API.POST(this.Dominio, 'ExtenderSesion', {}, Headers);
  }

  public TerminarSesion(): Observable<any> {
    return this.API.POST(this.Dominio, 'FinalizarSesion', {});
  }

  public IniciarSesionSIASE(Token): Observable<any>  {
    const Headers = {
      Token: Token,
      //ClienteIp: this._ip
    }
    console.log(Token);
    return this.API.POST(this.Dominio, "CrearSesionSIASE", {}, Headers);
  }

  /***************************** Perfil  *******************************/

  public ConsultarPerfil(Token): Observable<any>  {
    const Headers = {
      Token: Token,
    };
    return this.API.POST(this.Dominio, "ConsultarPerfil", {}, Headers);
  }
  public ValidarSolicitudCambioContrasena(Solicitud: string): Observable<any> {
    const Headers = {
      Solicitud: Solicitud,
    };
    console.log(Headers);
    return this.API.POST(this.Dominio, "ValidarSolicitudCambioContrasena", {}, Headers);
  }
  public SolicitarCambioContrasena(data): Observable<any> {
    const Headers = {
      NombreUsuario: data.NombreUsuario.toString(),
      Correo: data.Correo.toString(),
      OrganizacionId: data.OrganizacionId.toString(),
    };
    console.log(Headers);
    return this.API.POST(this.Dominio, "SolicitarCambioContrasena", {}, Headers);
  }

  public CambiarContrasena(data): Observable<any> {
    const Headers = {
      Solicitud: data.token,
      Contrasena: data.password,
    };
    return this.API.POST(this.Dominio, "CambiarContrasena", {}, Headers);
  }
  public CambiarRol(Token,AreaAcademicaId,RolId): Observable<any> {
    const Headers = {
      Token: Token,
      RolId: RolId.toString(),
      AreaAcademicaId: AreaAcademicaId.toString()
    };
    console.log(Headers);
    return this.API.POST(this.Dominio, "CambiarRol", {}, Headers);
  }

  /***************************** Dialog Sesion ********************************/

  public TokenExpirado() {
    return this.OpenDialogIniciarSesion().pipe(
      map((data: any) => { if (data.SesionInit) return true }),
      catchError(error => error)
    );
  }

  private OpenDialogIniciarSesion() {
    if (this.DialogLogin) this.DialogLogin.close();
    this.DialogLogin = this.dialog.open(LoginDialogComponent);
    this.DialogLogin.afterOpen().subscribe(result => { this.DialogLoginOpen = true });
    this.DialogLogin.afterClosed().subscribe(result => { this.DialogLoginOpen = false });
    return this.DialogLogin.afterClosed().pipe(
      map(data => data),
      catchError(error => error)
    );
  }

}
