import { Injectable } from '@angular/core';
import { of, Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _BackdropVisible: boolean = false;
  private _Icon: any;
  private _Message: string;
  private _Title: string;

  public BackdropType;
  constructor() {
  }

  /******************** Backdrop **********************/
  get BackdropVisible() { return this._BackdropVisible }
  set BackdropVisible(value: boolean) { this._BackdropVisible = value }

  get Message(): string { return this._Message }
  set Message(text: string) { this._Message = text }

  get Title(): string { return this._Title }
  set Title(text: string) { this._Title = text }

  public ShowBackdrop(type?: number, text?: string, title?: string) {
    this.BackdropType = type ? type : null;
    this._Message = text ? text : null;
    this._Title = title ? title : null;
    this._BackdropVisible = true;
  }
  public HideBackdrop() {
    this._BackdropVisible = false;
    this._Message = null;
    this._Title = null;
    this.BackdropType = null;
  }

}
