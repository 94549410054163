export class Unidad {
  UnidadId: number;
  DependenciaId: number;
  Nombre: string;
  NombreCorto: string;
  Estado: boolean;
  constructor(U) {
    this.UnidadId = U.UnidadId;
    this.DependenciaId = U.DependenciaId;
    this.Nombre = U.Nombre;
    this.NombreCorto = U.NombreCorto;
    this.Estado = U.Estado;
  }
}
