import { Cuenta } from "./Cuenta";

export class Persona{
    PersonaId: number;
    Nombre: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    CorreoAdicional: string;
    CURP: string;
    Estado;
    

    constructor(p){
        if(p!=null){     
            this.PersonaId = p.PersonaId;
            this.Nombre = p.Nombre;
            this.ApellidoPaterno = p.ApellidoPaterno;
            this.ApellidoMaterno = p.ApellidoMaterno;
            this.CorreoAdicional = p.CorreoAdicional;
            this.CURP = p.CURP;
            this.Estado = p.Estado;
        }
                
    }
}

export class PersonaRolId extends Persona{
    RolId: number;

    constructor(p){
        if(p!= null){
            super(p);
            this.RolId = p.RolId;
        }
    }
}

export class PersonaCuentas extends Persona{
    Cuentas: Cuenta[] = [];
    constructor(P){
        super(P);
        if(P){            
            if(P.Cuentas){
                this.Cuentas = P.Cuentas.map(C => new Cuenta(C));
            }
        }
    }
}

export class Usuario{
    Persona: Persona;
    Cuenta: Cuenta;

    constructor(P,C){
        if(P){
            this.Persona = new Persona(P);
        }
        if(C){
            this.Cuenta = new Cuenta(C);
        }
    }
}
