export class Archivo{
    Nombre: string;
    Extension: string;
    Peso: number;
    URL: string;
    TipoDocumentoId: number;
    FechaCreacion;
    Estado: boolean;

    constructor(D){
        if(D){
            this.Nombre = D.Nombre;
            this.Extension = D.Extension;
            this.Peso = D.Peso;
            this.URL = D.URL;
            this.TipoDocumentoId = D.TipoDocumentoId;
            this.FechaCreacion = new Date(D.FechaCreacion);
            this.Estado = D.Estado;
        }
    }
}

export class ArchivoEquipo extends Archivo{
    EquipoId: number;
    CarpetaId: number;
    CuentaId: number;
    DocumentoId: number;
    NombreFisico: string;

    constructor(D){
        super(D);
        if(D){
            this.EquipoId = D.EquipoId;
            this.CarpetaId = D.CarpetaId;
            this.CuentaId = D.CuentaId;
            this.DocumentoId = D.DocumentoId;
            this.NombreFisico = D.NombreFisico;
        }
    }
}


export class Documento{
    DocumentoId: number;
    TipoDocumentoId: number;
    Nombre: string;
    NombreFisico: string;
    Extension: string;
    Peso: number;
    URL: string;
    FechaCreacion: Date;
    Estado: boolean;

    constructor(D) {
        if (D) {
            this.DocumentoId = D.DocumentoId;
            this.Extension = D.Extension;
            this.FechaCreacion = new Date(D.FechaCreacion);
            this.Nombre = D.Nombre;
            this.NombreFisico = D.NombreFisico;
            this.Peso = D.Peso;
            this.TipoDocumentoId = D.TipoDocumentoId;
            this.URL = D.URL;
            this.Estado = D.Estado;
        }
    }
}

export class DocumentoEquipo extends Documento{
    CarpetaId: number;
    CuentaId: number;
    EquipoId: number;

    constructor(DE){
        super(DE);
        if(DE){
            this.CarpetaId = DE.CarpetaId;
            this.CuentaId = DE.CuentaId;
            this.EquipoId = DE.EquipoId;
        }
    }
}

export class DocumentoEntrega{
    EntregaId: number;
    CuentaId: number;
    ElementoId: number;
    TipoElementoId: number;
    Documento: Documento;
    EnEquipo: boolean;
    Estado: boolean;

    constructor(DE){
        if(DE){
            this.EntregaId = DE.EntregaId;
            this.CuentaId = DE.CuentaId;
            this.ElementoId = DE.ElementoId;
            this.TipoElementoId = DE.TipoElementoId;
            this.Documento = new Documento(DE.Documento);
            this.EnEquipo = DE.EnEquipo;
            this.Estado = DE.Estado;
        }
    }
}