
export class CompromisoEstudiante {
  EsAceptado: boolean;
  FechaAceptacion: Date;

  constructor(C) {
    if (C) {
      this.EsAceptado = C.EsAceptado;
      this.FechaAceptacion = new Date(C.FechaAceptacion);
    }
  }
}
