import { AreaAcademica } from "./AreaAcademica";
import { CompromisoEstudiante } from "./Compromiso";
import { CuentaAreaAcademica } from "src/app/models/Persona/Cuenta";


export class Cuenta {
    CuentaId: number;
    NombreUsuario: string;
    Estado: number;
    OrganizacionId: number;
    CorreoUniversitario: string;
    EnSIASE: boolean;
    AreasAcademicas: AreaAcademica[] = [];

    constructor(c) {
        if (c != null) {
            this.AreasAcademicas = [];
            this.CuentaId = c.CuentaId;
            this.NombreUsuario = c.NombreUsuario;
            this.Estado = c.Estado;
            this.OrganizacionId = 1;
            this.CorreoUniversitario = c.CorreoUniversitario;
            this.EnSIASE = c.EnSIASE;

            if (c.AreasAcademicas) {
                //let _AreasAcademicas = c.AreasAcademicas.map(A => A.AreaAcademica);
                this.AreasAcademicas = c.AreasAcademicas.map(AA => new CuentaAreaAcademica(AA));
            }
        }
    }
}

export class CuentaBase {
    Nombre: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    NombreUsuario: string;
    CuentaId: number;
    EnSIASE: boolean;
    constructor(C) {
        if (C != null) {
            this.Nombre = C.Nombre;
            this.ApellidoPaterno = C.ApellidoPaterno;
            this.ApellidoMaterno = C.ApellidoMaterno;
            this.CuentaId = C.CuentaId;
            this.NombreUsuario = C.NombreUsuario || C.Matricula;
            this.EnSIASE = C.EnSIASE;
        }
    }
}
export class CuentaRol extends CuentaBase {
    RolId: number;
    constructor(C, Rol) {
        super(C);
        this.RolId = Rol;
    }
}
export class CuentaGeneral {
    Nombre: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    NombreUsuario: string;
    CuentaId: number;
    EnSIASE: boolean;

    constructor(P,C){

        if(P != null){
            this.Nombre = P.Nombre;
            this.ApellidoPaterno = P.ApellidoPaterno;
            this.ApellidoMaterno = P.ApellidoMaterno;
            this.CuentaId = P.CuentaId;
            this.NombreUsuario = P.NombreUsuario;
            this.EnSIASE = P.EnSIASE;
        }
        if(C!= null){
            this.CuentaId = C.CuentaId;
            this.NombreUsuario = C.NombreUsuario;
            this.EnSIASE = C.EnSIASE;
        }
    }
}

export class Profesor{
    Nombre: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    NombreUsuario: string;
    CorreoUniversitario: string;
    CuentaId: number;
    EnSIASE: boolean;


    constructor(P,C){
        if (P != null) {
            this.Nombre = P.Nombre;
            this.ApellidoPaterno = P.ApellidoPaterno;
            this.ApellidoMaterno = P.ApellidoMaterno;
            this.CuentaId = P.CuentaId;
            this.NombreUsuario = P.NombreUsuario;
            this.CorreoUniversitario = P.CorreoUniversitario;
            this.EnSIASE = P.EnSIASE;
        }
        if (C!= null) {
            this.CuentaId =  C.CuentaId;
            this.NombreUsuario = C.NombreUsuario;
            this.CorreoUniversitario = C.CorreoUniversitario;
            this.EnSIASE = C.EnSIASE;
        }
    }
}

export class Estudiante {
    Nombre: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    NombreUsuario: string;
    CuentaId: number;
    EnSIASE: boolean;
    GrupoId: number;
    Estado: boolean;

    constructor(P,C) {
        if (P != null) {
            this.Nombre = P.Nombre;
            this.ApellidoPaterno = P.ApellidoPaterno;
            this.ApellidoMaterno = P.ApellidoMaterno;
            this.CuentaId = P.CuentaId;
            this.NombreUsuario = P.NombreUsuario;
            this.EnSIASE = P.EnSIASE;
            this.GrupoId = P.GrupoId;
            this.Estado = P.Estado;
        }
        if (C != null) {
            this.CuentaId = C.CuentaId;
            this.NombreUsuario = C.NombreUsuario;
            this.EnSIASE = C.EnSIASE;
            this.GrupoId = C.GrupoId;
            this.Estado = C.Estado;
        }
    }
}

export class EstudianteCurso{
    Nombre: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    NombreUsuario: string;
    CuentaId: number;
    GrupoId: number;
    GrupoEstudianteId: number;
    EnSIASE: boolean;
    Estado: boolean;
    CorreoAdicional: string;
    CorreoUniversitario: string;
    Compromiso: CompromisoEstudiante;


    constructor(E){
        if(E != null){
            this.Nombre = E.Nombre;
            this.ApellidoPaterno = E.ApellidoPaterno;
            this.ApellidoMaterno = E.ApellidoMaterno;
            this.NombreUsuario = E.NombreUsuario;
            this.CuentaId = E.CuentaId;
            this.GrupoId = E.GrupoId;
            this.GrupoEstudianteId = E.GrupoEstudianteId;
            this.EnSIASE = E.EnSIASE;
            this.Estado = E.Estado;
            this.CorreoAdicional = E.CorreoAdicional;
            this.CorreoUniversitario = E.CorreoUniversitario;
            if(E.Compromiso){
                this.Compromiso = new CompromisoEstudiante(E.Compromiso)
            }
        }

    }
}
