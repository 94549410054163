
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';

import { SeguridadService as SeguridadServiceOld } from 'src/app/shared/services/seguridad.service';
import { PerfilService as PerfilServiceOld} from 'src/app/shared/services/perfil.service';
import { Perfil as PerfilOld } from 'src/app/shared/DTO/Perfil';
import { GeneralService } from 'src/app/shared/services/general.service';


import { SeguridadService } from 'src/app/services/Seguridad/Seguridad.service';
import { SesionService } from 'src/app/services/Sesion/Sesion.service';
import { SnackBarService } from 'src/app/services/Utils/AngularMaterial/SnackBar/SnackBar.service';
import { Perfil } from 'src/app/models/Persona/Perfil';
import { Cuenta } from 'src/app/models/Persona/Cuenta';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.css']
})
export class LoginDialogComponent implements OnInit {

  public Cuenta: Cuenta;
  public DatosLogin = { usuario: null, password: null};
  public section = 1;
  public messageError;
  public perfil: PerfilOld;

  constructor(
    private SeguridadSVC: SeguridadService,
    private SesionSVC: SesionService,
    private SnackBar: SnackBarService,
    private seguridad_service: SeguridadServiceOld,
    private perfil_service: PerfilServiceOld,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<LoginDialogComponent>,
  ) {
    console.log(this.SesionSVC.GetSesion());
    console.log(this.SesionSVC.SesionStorage);

    console.log(this.SesionSVC.Perfil);
    this.Cuenta = this.SesionSVC.Perfil.Cuenta;//this.perfil_service.getCuenta();
    this.DatosLogin.usuario = this.Cuenta.NombreUsuario;
  }

  ngOnInit() {}

  public onSubmitLogin(form?) {
    this.IniciarSesion(true);
  }

  public IniciarSesion(Imperative: boolean) {
    console.log('Login');
    this.SeguridadSVC.IniciarSesion(this.DatosLogin, Imperative).subscribe(res => {
      console.log(res);
      if (!res.Error) {
        this.SnackBar.OpenSnackBarSuccess('La sesión se ha iniciado con éxito', 4000);
        this.SesionSVC.UpdateSesion(res.Sesion);
        this.SesionSVC.GetSesionStorage();
        this.ObtenerPerfil(res.Sesion.Token);
      }
    });
  }

  private ObtenerPerfil(Token) {
    this.SeguridadSVC.ConsultarPerfil(Token).subscribe(res => {
      console.log(res);
      if (!res.Error) {
        /*let P = new Perfil({
          Persona: res.Persona,
          Cuenta: res.Persona.Cuentas[0],
          AreasAcademicas: res.Persona.Cuentas[0].AreasAcademicas
        });
        console.log(P);
        this.SesionSVC.Perfil = P;*/

        // start Old
        this.perfil_service.InicioSesion(res);
        // end Old

        this.dialogRef.close({
          SesionInit: true
        });
      }
    });
  }

  public getPerfil(Token): void{
   this.seguridad_service.getPerfil(Token).subscribe(
    result => {
      console.log(result);
      if(result["Code"]){
        this.snackbar.open(result["Message"], "X", {
          duration: 4000,
          panelClass: ["snackbar-error","action-close"],
          verticalPosition: 'bottom',
        });
      }else{
        this.perfil = new PerfilOld(result);
        this.perfil_service.setPerfil(this.perfil);
        this.dialogRef.close({
          SesionInit: true
        });
      }
     },error => {
      console.log(error);
    });

  }

  public Logout(): void {
    this.dialogRef.close(false);
    this.seguridad_service.Logout().subscribe();
  }

}


