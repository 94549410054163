import { Etapa } from "./Etapa";
import { Modulo } from "./Modulo";
import { PIA } from "./PIA";


export class Estructura{
    EstructuraId: number;
    CursoId: number;
    ModeloId: number;
    TipoEstructuraId: number;
    Etapas: Etapa[] = [];
    Modulos: Modulo[] = [];
    ProductoIntegrador: PIA;
    Estado: boolean;

    constructor(E){
        if(E){
            this.EstructuraId = E.EstructuraId;
            this.CursoId = E.CursoId;
            this.ModeloId = E.ModeloId;
            this.TipoEstructuraId = E.TipoEstructuraId;
            this.Estado = E.Estado;
            if(E.Etapas)
                this.Etapas = E.Etapas.map(E => new Etapa(E));
            if(E.Modulos)
                this.Modulos = E.Modulos.map(M => new Modulo(M));
            if(E.ProductoIntegrador && E.ProductoIntegrador.ProductoIntegradorId != 0)
                this.ProductoIntegrador = new PIA(E.ProductoIntegrador);
            
        }
    }
}

export class ElementoEvaluable{
    ElementoId: number;
    TipoElementoId: number;
    Descripcion: string;
    Estado: boolean;

    constructor(EE){
        if(EE){
            this.ElementoId = EE.ElementoId;
            this.TipoElementoId = EE.TipoElementoId;
            this.Descripcion = EE.Descripcion;
            this.Estado = EE.Estado;
        }
    }
}