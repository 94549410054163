import { Sesion } from "./Sesion";
import { Perfil } from "../Persona/Perfil";
import { Curso, CursoBase } from "../Curso/Curso";
import { Rol } from "../Persona/Rol";

export class SesionStorage {
  Sesion: Sesion;
  Perfil: Perfil;
  Curso: Curso;
  constructor(SS) {
    if (SS) {
      this.Sesion = new Sesion(SS.Sesion);
      this.Perfil = SS.Perfil ? new Perfil(SS.Perfil) : null;
      this.Curso = SS.Curso ? new Curso(SS.Curso) : null;
    }
  }
}
