import { Component, OnInit, Inject } from '@angular/core';
import { SeguridadService } from 'src/app/shared/services/seguridad.service';
import { GeneralService } from 'src/app/shared/services/general.service';
import { EncuestasService } from 'src/app/shared/services/encuestas.service';
import { Encuesta } from 'src/app/shared/DTO/Encuesta';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-encuesta-cambiar-estado',
  templateUrl: './cambiar-estado.component.html',
  styleUrls: ['./cambiar-estado.component.css']
})
export class EncuestaCambiarEstadoComponent implements OnInit {

  public Encuesta: Encuesta;

  constructor(
    private seguridad_service: SeguridadService,
    private general_service: GeneralService,
    private encuestas_service: EncuestasService,
    private dialogRef: MatDialogRef<EncuestaCambiarEstadoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.Encuesta = new Encuesta(this.data.Encuesta.Encuesta, 1);
    this.Encuesta.EstadoEncuestaId = this.data.Encuesta.Estado;
    console.log(this.Encuesta.EstadoEncuestaId);
  }

  public ActualizarEncuestaEstado() {
    const data = {
      Encuesta: this.Encuesta
    };
    this.encuestas_service.UpdateEncuesta(data).subscribe(
      result => {
        console.log(result);
        if (!result.Code) {
          switch (this.Encuesta.EstadoEncuestaId) {
            case 2: this.general_service.snackbarSucces('Encuesta Publicada con éxito',  3000); break;
            case 3: this.general_service.snackbarSucces('Encuesta Suspendida con éxito', 3000); break;
            case 4: this.general_service.snackbarSucces('Encuesta Terminada con éxito',  3000); break;
          }
          this.dialogRef.close();
          this.Encuesta.EstadoEncuestaId = new Encuesta(result.Encuesta, 1).EstadoEncuestaId;
          this.encuestas_service.setEncuestaSelected(this.Encuesta);
          this.dialogRef.close({
            Encuesta: this.Encuesta
          });
        } else {
          this.general_service.snackbarError(result.Message, 3000);
          if (this.seguridad_service.isSesionExpired(result.Code)) {
            this.seguridad_service.sesionExpired().subscribe(result => this.ActualizarEncuestaEstado());
          }
        }
      }, error => {
        console.log(error);
        this.general_service.snackbarError(JSON.stringify(error), 3000);
    });
  }
}
