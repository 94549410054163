
export abstract class RolBase {
  RolId: number;
  Descripcion: string;
  constructor(R) {
    this.RolId = R.RolId;
    this.Descripcion = R.Descripcion;
  }
}


export class Rol implements RolBase {
  RolId: number = 0;
  Descripcion: string;
  Nivel: number;
  Estado: number;
  constructor(R, D?, E?, N?){
    if (R) {
      this.RolId = R.hasOwnProperty('RolId') ? R.RolId : R;
      this.Descripcion = D ? D :R.Descripcion;
      this.Estado = E ? E : R.Estado;
      this.Nivel = N ? N : R.Nivel;
    }
  }
}
