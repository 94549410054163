import { pipeFromArray } from "../../../../node_modules/rxjs/internal/util/pipe";
import { Evidencia } from "./Evidencia";
import { PIA } from "./PIA";
import { ExamenEtapa } from "./Examen";

export class Etapa{
    EtapaId: number;
    EstructuraId: number;
    Posicion: number;
    Nombre: string;
    ElementoCompetencia: string;
    Evidencias: Evidencia[] = [];
    EtapaExamenes: ExamenEtapa[] = [];
    Elementos = [];
    Estado: boolean;

    constructor(E){
        if(E){
            this.EtapaId = E.EtapaId;
            this.EstructuraId = E.EstructuraId;
            this.Posicion = E.Posicion;
            this.Nombre = E.Nombre;
            this.ElementoCompetencia = E.ElementoCompetencia;
            this.Estado = E.Estado;
            this.Evidencias = E.Evidencias.map(E => new Evidencia(E));
            this.EtapaExamenes = E.EtapaExamenes.map(E => new ExamenEtapa(E));
            this.Elementos = E.Evidencias.concat(E.EtapaExamenes);
        }
    }

}
