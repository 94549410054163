import { Estudiante, EstudianteCurso } from "../Persona/Estudiante";

export interface GrupoBase {
  GrupoId: number;
  CursoId: number;
  Nombre: string;
  EnSIASE: boolean;
  Estado: boolean;
}

export class Grupo implements GrupoBase {
  GrupoId: number = 0;
  CursoId: number;
  Nombre: string;
  EnSIASE: boolean;
  Estado: boolean = true;
  EstadoProcesoId: number;

  constructor(G) {
    if (G) {
      this.GrupoId = G.GrupoId;
      this.CursoId = G.CursoId;
      this.Nombre = G.Nombre;
      this.EnSIASE = G.EnSIASE;
      this.Estado = G.Estado;
      this.EstadoProcesoId = G.EstadoProcesoId;
    }
  }
}

export class GrupoEstudiantes extends Grupo {
  Estudiantes: EstudianteCurso[] = [];
  constructor(G) {
    super(G);
    if (G) {
      if(G.Estudiantes) {
        G.Estudiantes.map(E => E.GrupoId = G.GrupoId);
        this.Estudiantes = G.Estudiantes.map(E => new EstudianteCurso(E));
      }
    }
  }
}
