import {MatPaginatorIntl} from '@angular/material';
export class MatPaginatorIntCustom extends MatPaginatorIntl {
  itemsPerPageLabel = '';
  nextPageLabel     = 'Página Siguiente';
  previousPageLabel = 'Página Anterior';
  firstPageLabel = "Primera Página";
  lastPageLabel = "Última Página";

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    var _items = length%pageSize;
    var numPages = length/pageSize | 0;
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    if(_items != 0){
      numPages += 1;
    }

    if (length == 0 || pageSize == 0) {
          return `Página 0 de ${length}`;
    }
    return `${startIndex + 1} - ${endIndex} de ${length}`;//`Página ${page + 1} de ${numPages}`
  };
    /*length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} de ${length}`; }*/


}
