import { CONSTANTS } from 'src/app/shared/constants';
import { Constants } from 'msal';

export const OAuthSettings = {
  //appId: '2b78117c-ce71-4272-9737-908076e487e9',
  //appId: '4d943e68-274c-4e93-961d-b9260ba37b4c',
  //appId: 'cdde2a21-14df-47e5-894c-5fa51901f7f9',
  //appId: '23d86c22-7a26-43c1-a2b1-877776f0e8c5',

  //Nexus7Graph nexus.uanl@outlook.com
  appId: 'd997adeb-b94d-4ca5-b7c4-e5a20fce06cc',
  LogoutRedirectUri: CONSTANTS.URL +'/#/App',
  //LogoutRedirectUri:'http://localhost:4200/#/Redireccionando',
  tenant: 'uanl.edu.mx',

  scopes: [
    "user.read",
    "Team.ReadBasic.All",
    //"./default",
    //"Directory.ReadWrite.All",
    //"Group.ReadWrite.All",
    //"Directory.AccessAsUser.All",
    //"calendars.read",
    "files.read.all",
    //"groups.read.all",
    //"group.create"
  ]
};
