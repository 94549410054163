import { AvisoCuenta } from "./AvisoCuenta";

export class AvisoRol {
  AvisoDestinoRolId: number = 0;
  RolId: number;
  Descripcion: string;
  Cuentas: AvisoCuenta[] = [];
  Estado: boolean = true;

  constructor(R){
    if (R) {
      this.AvisoDestinoRolId = R.AvisoDestinoRolId;
      this.Descripcion = R.Descripcion;
      this.RolId = R.RolId;
      this.Estado = R.Estado;
      this.Cuentas = R.Cuentas.map( C => new AvisoCuenta(C));
    }
  }
}
