import { TextEditor } from "./utils/TextEditor";

export class Compromiso {
    CompromisoId: number;
    CursoId: number;
    Descripcion: string;
    Estado: boolean;
    Estudiante: CompromisoEstudiante;

    constructor(C) {
        if (C) {
            this.CompromisoId = C.CompromisoId;
            this.Descripcion = new TextEditor().CleanFroalaEditor(C.Descripcion);
            this.Estado = C.Estado;
            if (C.Estudiante) {
                this.Estudiante = new CompromisoEstudiante(C.Estudiante);
            }
        }
    }
}

export class CompromisoEstudiante {
    EsAceptado: boolean;
    FechaAceptacion;

    constructor(C){
        if(C){
            this.EsAceptado = C.EsAceptado;
            this.FechaAceptacion = new Date(C.FechaAceptacion); 
        }
    }
}