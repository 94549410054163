import { Persona } from "./Persona";
import { Cuenta, CuentaAreaAcademica, CuentaAreaAcademicaRol, CuentaPersona } from "./Cuenta";
import { Rol } from "./Rol";

export class Perfil {
  Persona: Persona;
  Cuenta: CuentaPersona;
  AreasAcademicas: CuentaAreaAcademica[] = [];
  AreaAcademica: CuentaAreaAcademica;
  Rol: CuentaAreaAcademicaRol;

  constructor(P) {
    console.log(P);
    this.Persona = new Persona(P.Persona);
    this.Cuenta = new CuentaPersona(P.Cuenta);
    this.AreasAcademicas = P.AreasAcademicas.map(AA => new CuentaAreaAcademica(AA));
    this.AreaAcademica = P.AreaAcademica ? P.AreaAcademica : this.AreaAcademicaRolPrincipal();
    this.Rol = P.Rol ? P.Rol : this.RolPrincipal();
  }
  public AreaAcademicaRolPrincipal(): CuentaAreaAcademica {
    console.log(this.AreasAcademicas);
    let AreaAcademicaRolPrincipal: CuentaAreaAcademica = null;
    this.AreasAcademicas.forEach(AA => {
      AA.Roles.forEach(R => {
        if (R.EsRolPrincipal) {
          AreaAcademicaRolPrincipal = AA;
        }
      });
    });
    if(!AreaAcademicaRolPrincipal) AreaAcademicaRolPrincipal = this.AreasAcademicas[0];
    return AreaAcademicaRolPrincipal;
  }
  public RolesAreaAcademica(AreaAcademicaId: number) {
    return this.AreaAcademica.Roles;
  }
  public Roles() {
    return this.AreaAcademica.Roles;
  }
  public RolPrincipal(): CuentaAreaAcademicaRol {
    return this.AreaAcademica.Roles.find(R => R.EsRolPrincipal === true);
  }
}
