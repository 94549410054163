import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http';

import { map, catchError } from 'rxjs/operators';

import { Headers } from '@angular/http';

import { CONSTANTS } from '../constants';
import { PerfilService } from './perfil.service';
import { GeneralService } from './general.service';



@Injectable({
  providedIn: 'root'
})

export class EncuestasService{

   /* Encuesta Tipo Destino */
   public ENCUESTA_TIPO_DESTINO = [
    { TipoDestinoId: 1, Descripcion: "Organización" },
    { TipoDestinoId: 2, Descripcion: "Área Académica" },
    { TipoDestinoId: 3, Descripcion: "Unidad de Aprendizaje" },
  ];
  /* Encuesta Estado */
  public ENCUESTA_ESTATUS = [
      { EstadoEncuestaId: 1, Descripcion: "En Proceso" },
      { EstadoEncuestaId: 2, Descripcion: "Publicada" },
      { EstadoEncuestaId: 3, Descripcion: "Suspendida" },
      { EstadoEncuestaId: 4, Descripcion: "Terminada" },
  ];

  private HEADER;
  private DomainService = 'Encuesta';

  private Encuesta;

  constructor(
    private http: HttpClient,
    private perfil_service:  PerfilService,
    private general_service: GeneralService
  ){
    this.HEADER = {
      headers: new HttpHeaders()
      .append("Token", this.perfil_service.getToken())
      .append("AreaAcademicaId", this.perfil_service.getAreaAcademicaSesion().AreaAcademicaId.toString())
      .append("RolId", this.perfil_service.getRolSesion().RolId.toString())
    }
  }

  private updateHeader(){ this.HEADER = this.general_service.getUpdateHeader() }

  /* Encuestas */
  public setEncuestaSelected(E){ this.Encuesta = E }
  public getEncuestaSelected(){ return this.Encuesta }

  public getEncuestas(_CursoId){
    return this.ExecuteRequest('/ConsultarEncuestas', {
      CursoId: _CursoId
    });
  }

  public getEncuestasCursoAreaAcedemica(_CursoId) {
    return this.ExecuteRequest('/ConsultarEncuestasAdministradorAreaAcademicaCurso', {
      CursoId: _CursoId
    });
  }

  public getEncuestasAsignadas(_CursoId) {
    return this.ExecuteRequest('/ConsultarEncuestasAsignadas', {
      CursoId: _CursoId
    });
  }

  public getEncuesta(_EncuestaId){
    return this.ExecuteRequest('/ConsultarEncuesta', {
      EncuestaId: _EncuestaId
    });
  }

  public getEncuestaResultados(data){
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Encuesta/ConsultarEncuestaResultado", data, this.HEADER )
    .pipe(
      map(data => this.general_service.handleResult(data)),
      catchError(error => this.general_service.handleError(error))
    );
  }

  public ConsultarRespuestasAbiertas(_EncuestaReactivoId) {
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Encuesta/ConsultarRespuestasAbiertas", {
      EncuestaReactivoId: _EncuestaReactivoId
    }, this.HEADER )
    .pipe(
      map(data => this.general_service.handleResult(data)),
      catchError(error => this.general_service.handleError(error))
    );
  }
  public getEncuestaResponder(data){
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Encuesta/ConsultarResponderEncuesta", data, this.HEADER )
    .pipe(
      map(data => this.general_service.handleResult(data)),
      catchError(error => this.general_service.handleError(error))
    );
  }

  public UpdateEncuesta(data){
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Encuesta/ActualizarEncuesta", data, this.HEADER )
    .pipe(
      map(data => this.general_service.handleResult(data)),
      catchError(error => this.general_service.handleError(error))
    );
  }

  public CopiarEncuesta(_Encuesta, _EncuestaOrigenId){
    let data = {
      Encuesta: _Encuesta,
      EncuestaOrigenId: _EncuestaOrigenId
    }
    console.log(data);
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Encuesta/CopiarEncuesta", data, this.HEADER )
    .pipe(
      map(data => this.general_service.handleResult(data)),
      catchError(error => this.general_service.handleError(error))
    );
  }
  public ReiniciarEncuesta(_EncuestaId) {
    let data = {
      EncuestaId: _EncuestaId,
    }
    console.log(data);
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT + 'Encuesta/ReiniciarEncuesta', data, this.HEADER )
    .pipe(
      map(data => this.general_service.handleResult(data)),
      catchError(error => this.general_service.handleError(error))
    );
  }

  /***************************** Reactivos ************************************/

  public getEncuestaReactivos(data){
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Encuesta/ConsultarEncuestaReactivos", data, this.HEADER )
    .pipe(
      map(data => this.general_service.handleResult(data)),
      catchError(error => this.general_service.handleError(error))
    );
  }

  public UpdateReactivo(data){
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Encuesta/ActualizarEncuestaReactivos", data, this.HEADER )
    .pipe(
      map(data => this.general_service.handleResult(data)),
      catchError(error => this.general_service.handleError(error))
    );
  }

  public getNextPosicionReactivo(){
    return (this.Encuesta.Reactivos.length + 1);
  }

  public getTipoReactivo(TipoReactivoId){
    return CONSTANTS.TiposReactivo.filter(TR => TR.TipoReactivoId == TipoReactivoId)[0];
  }

  /**************************** Ejecucion ***********************/
  public UpdateEjecucionEncuesta(data){
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Encuesta/ActualizarEjecucionEncuesta", data, this.HEADER )
    .pipe(
      map(data => this.general_service.handleResult(data)),
      catchError(error => this.general_service.handleError(error))
    );
  }
  public UpdateEjecucionCuenta(data){
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT+"Encuesta/ActualizarEjecucionCuenta", data, this.HEADER )
    .pipe(
      map(data => this.general_service.handleResult(data)),
      catchError(error => this.general_service.handleError(error))
    );
  }


  public getTipoDestino(id){
    return this.ENCUESTA_TIPO_DESTINO.find(TD => TD.TipoDestinoId == id);
  }
  public getEstatus(id){
    return this.ENCUESTA_ESTATUS.find(E => E.EstadoEncuestaId == id);
  }


  /********************************************* REQUEST **********************************************/
  private ExecuteRequest(_Method,_Data){
    this.updateHeader();
    return this.http.post(CONSTANTS.API_ENDPOINT + this.DomainService + _Method, _Data, this.HEADER )
    .pipe(
        map(data => this.general_service.handleResult(data)),
        catchError(error => this.general_service.handleError(error))
    );
  }


}
