import { Injectable } from '@angular/core';

import { CONSTANTS } from '../constants';
import { Rol } from '../DTO/Rol';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  public getRoles(): Rol[]{
    return CONSTANTS.ROLES;
  }
  public getRol(RolId): Rol{
    return CONSTANTS.ROLES.find(R => R.RolId == RolId);
  }
  public getRolDescripcion(RolId): String{
    return this.getRol(RolId).Descripcion;
  }

  public getRolesAreaAcademica(){
    return this.getRoles().filter(R => 
      R.RolId == 2 || 
      R.RolId == 3 || 
      R.RolId == 4 || 
      R.RolId == 5 )
  }
  public getRolesIntegrantesUA(): Rol[]{
    return CONSTANTS.ROLES.filter(R => R.RolId == 3 || R.RolId == 4 || R.RolId == 5);
  }
}