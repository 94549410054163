export class NivelAcademico {
  NivelAcademicoId: number;
  Nombre: string;
  Abreviatura: string;
  Estado: boolean;
  constructor(NA) {
    if(NA) {
      this.NivelAcademicoId = NA.NivelAcademicoId;
      this.Nombre = NA.Nombre;
      this.Abreviatura = NA.Abreviatura;
      this.Estado = NA.Estado;
    }
  }
}
