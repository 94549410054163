import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';

import { CONSTANTS } from '../constants';
import { PerfilService } from './perfil.service';
import { LoginDialogComponent } from 'src/app/components/auth/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material';

@Injectable({
  providedIn: 'root'
})

export class SeguridadService {

  private DomainService = 'Seguridad';
  private dialogLogin;
  private dialogLoginIsOpen;

  constructor(
    private http: HttpClient,
    private perfil_service: PerfilService,
    private dialog: MatDialog
  ) { }

  public Login(data, imperative): Observable<any> {
    const headers = {
      headers: new HttpHeaders()
        .append("NombreUsuario", data.usuario)
        .append("Password", data.password)
        .append("Imperative", imperative)
        .append("OrganizacionId", "1")
    };
    return this.ExecuteRequest("/CrearSesion", {}, headers);
  }

  public getPerfil(Token): Observable<any>  {
    const headers = { headers: new HttpHeaders().append("Token", Token) };
    return this.ExecuteRequest("/ConsultarPerfil", {}, headers);
  }

  public LoginSIASE(Token): Observable<any>  {
    const headers = { headers: new HttpHeaders().append("Token", Token) };
    return this.ExecuteRequest("/CrearSesionSIASE", {}, headers);
  }

  public Logout(): Observable<any> {
    const headers = {
      headers: new HttpHeaders()
        .append("Token", this.perfil_service.getToken())
        .append("AreaAcademicaId", this.perfil_service.getAreaAcademicaSesion().AreaAcademicaId.toString())
        .append("RolId", this.perfil_service.getRolSesion().RolId.toString())
    }
    return this.ExecuteRequest("/FinalizarSesion", {}, headers).pipe(
      map(data => {
        this.RemoveLocalStorageSesion();
        window.location.href = '';
      }),
      catchError(error => error)
    );
  }

  public ReactivarSesion(data, imperative): Observable<any>  {
    const headers = {
      headers: new HttpHeaders()
        .append("NombreUsuario", data.usuario)
        .append("Password", data.password)
        .append("Imperative", imperative)
        .append("OrganizacionId", "1")
    };
    return this.ExecuteRequest("/ReactivarSesion", {}, headers);
  }

  public ExtenderSesion(Token) {
    const headers = { headers: new HttpHeaders().append("Token", Token) };
    return this.ExecuteRequest("/ExtenderSesion", {}, headers);
  }

  public SolicitarCambioContrasena(data): Observable<any> {
    const headers = {
      headers: new HttpHeaders()
        .append("NombreUsuario", data.NombreUsuario)
        .append("Correo", data.Correo)
        .append("OrganizacionId", data.OrganizacionId)
    }
    return this.ExecuteRequest("/SolicitarCambioContrasena", {}, headers);
  }

  public ActualizarPassword(data): Observable<any> {
    const headers = {
      headers: new HttpHeaders()
        .append("Solicitud", data.token)
        .append("Contrasena", data.password)
    }
    return this.ExecuteRequest("/CambiarContrasena", {}, headers);
  }

  private ExecuteRequest(_Method, _Data, _Header) {
    return this.http.post(CONSTANTS.API_ENDPOINT + this.DomainService + _Method, _Data, _Header )
    .pipe(
        map(data => data ),
        catchError(error => error)
    );
  }

  /*************************** Sesion Expirada *******************************/
  public isSesionExpired(Code) {
    if (Code == 2004 || Code == 2011) { return true }
    else { return false }
  }

  public sesionExpired() {
    return this.openDialogLogin().pipe(
      map((data: any) => { if (data.SesionInit) return true }),
      catchError(error => error)
    );
  }

  public RemoveLocalStorageSesion() {
    localStorage.removeItem('UanlNexus7Sesion');
  }

  private openDialogLogin() {
    if (this.dialogLogin) this.dialogLogin.close();
    this.dialogLogin = this.dialog.open(LoginDialogComponent);
    this.dialogLogin.afterOpen().subscribe(result => { this.dialogLoginIsOpen = true });
    this.dialogLogin.afterClosed().subscribe(result => { this.dialogLoginIsOpen = false });
    return this.dialogLogin.afterClosed().pipe(
      map(data => data),
      catchError(error => error)
    );
  }




}
