import { NivelAcademico } from "./NivelAcademico";
import { Rol } from "../Persona/Rol";
import { Dependencia } from "./Dependencia";
import { Unidad } from "./Unidad";

export class AreaAcademica {
  _Unidad: Unidad;

  AreaAcademicaId: number;
  Nombre: string;
  EsBloqueado: boolean;
  Estado: number;
  Roles: Rol[] = [];
  Dependencia: Dependencia;
  NivelAcademico: NivelAcademico;
  NivelAcademicoId: number;
  UnidadId: number;

  constructor(AA){
      if(AA) {
        this.AreaAcademicaId = AA.AreaAcademicaId;
        this.Nombre = AA.Nombre;
        this.Dependencia = new Dependencia(AA.Dependencia);
        this.NivelAcademico = new NivelAcademico(AA.NivelAcademico);
        this.EsBloqueado = AA.EsBloqueado;
        this.Estado = AA.Estado;

        this.Roles = AA.Roles ? AA.Roles.map(R => new Rol(R)) : null;
        this.NivelAcademicoId = AA.NivelAcademicoId ? AA.NivelAcademicoId : null;
        this.UnidadId = AA.UnidadId ? AA.UnidadId : null;
        this._Unidad = AA.UnidadId ? this.Dependencia.Unidades.find(U => U.UnidadId === this.UnidadId) : null;
      }
  }

  set Unidad(U) { this._Unidad = U }
  get Unidad() { return this._Unidad }
}
