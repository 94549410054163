import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { RequestOptions, ResponseContentType } from '@angular/http';

import { map, catchError } from 'rxjs/operators';

import { CONSTANTS } from '../constants';
import { Perfil, Sesion } from '../DTO/Perfil';
import { Cuenta } from '../DTO/Cuenta';
import { Rol } from '../DTO/Rol';
import { AreaAcademica } from '../DTO/AreaAcademica';
import { Router } from '@angular/router';
import { RolService} from './rol.service';


@Injectable({
  providedIn: 'root'
})

export class PerfilService {
    public Perfil: Perfil;
    private Sesion: Sesion;

    constructor(
        private router: Router,
        private rol_service: RolService,
    ) {}

    /************************************************* SESION ********************************************/
    // Inicializa las Variables de SESION
    public initSesion(data){
        this.setPerfil(new Perfil(data.Perfil));
    }
    public setSesion(S){ this.Perfil.Sesion = S }
    public getSesion(){ return this.Perfil.Sesion }

    //Verifica el Rol y redirecciona a la seccion corrspondiente
    public selectModule(){
        console.log(this.getRolSesion());
        //AdminGral
        if(this.getRolSesion().RolId == 6) this.router.navigate(["AdminGral"]);

        //Auditor
        if(this.getRolSesion().RolId == 12) this.router.navigate(["Auditor"]);

        //DENMS
        if(this.getRolSesion().RolId == 13) this.router.navigate(["Seguimiento"]);
        //Seguimiento Académico
        if(this.getRolSesion().RolId == 14) this.router.navigate(["Seguimiento"]);

        //Monitoreo
        if(this.getRolSesion().RolId == 17) this.router.navigate(["Desempeno"]);

        //Administrador
        if(this.getRolSesion().RolId == 1) this.router.navigate(["Administrador"]);

        //Administrador Asistente
        if(this.getRolSesion().RolId == 10) this.router.navigate(["Administrador"]);

        //Profesor
        if(this.getRolSesion().RolId == 2) this.router.navigate(["Profesor"]);

        //Estudiante
        if(this.getRolSesion().RolId == 5) this.router.navigate(["Estudiante"]);
    }

    public getToken(): string{ return this.Perfil.Sesion.Token }

    public setTiempoFinSesion(): void {}

    public getTiempoRestante(): void { this.Perfil.Sesion.TiempoRestante }

    public InicioSesion(data){
      this.setPerfil(new Perfil(data));
    }

    /****************************************** Perfil *****************************************/
    public setPerfil(P: Perfil): void {
        // Verifica que no este seleccioonado un Rol de Sesion
        if (!P.Sesion.AreaAcademica) {
            P.Sesion.AreaAcademica = P.Persona.Cuentas[0].AreasAcademicas[0];
        }
        this.Perfil = P;
        let Roles = this.rol_service.getRoles();
        this.Perfil.Persona.Cuentas[0].AreasAcademicas.forEach(AA => {
            AA.Roles.forEach(R => {
                R.Descripcion = Roles.find(_Rol => _Rol.RolId === R.RolId).Descripcion;
            });
        });
        this.savePerfil();
    }
    public getPerfil(): Perfil{ return this.Perfil; }
    /* Guardar Informacion en Sesion */
    public savePerfil(): void{
        localStorage.setItem("UanlNexus7Sesion",JSON.stringify(this.Perfil));
    }
    public getPerfilSaved(){
        return JSON.parse(localStorage.getItem("UanlNexus7Sesion"));
    }
    public getNombrePersona(){
        return this.Perfil.Persona.Nombre+" "+this.Perfil.Persona.ApellidoPaterno+" "+this.Perfil.Persona.ApellidoMaterno;
    }
    public getNombreUsuario(){
        return this.getCuenta().NombreUsuario;
    }

    /****************************************** Cuenta **************************************/
    public getCuenta(): Cuenta{
        return this.Perfil.Persona.Cuentas[0];
    }
    //Organizacion
    public getOrganizacion(): number{
        return this.Perfil.Persona.Cuentas[0].OrganizacionId;
    }

    //Roles
    public getRoles(): Rol[]{
        let _RolesTotal : Rol[] = [];
        let _AreasAcademicas: AreaAcademica[] = this.Perfil.Persona.Cuentas[0].AreasAcademicas;
        console.log(_AreasAcademicas);
        for(var i in _AreasAcademicas){
            let _Roles = _AreasAcademicas[i].Roles;
            console.log(_Roles);
            for(var j in _Roles){
                console.log(_Roles[j]);
                console.log(_RolesTotal.includes(_Roles[j]));
                console.log(_RolesTotal.find(R => R.RolId == _Roles[j].RolId));
                _Roles[j].Descripcion = this.rol_service.getRoles().find(R => R.RolId == _Roles[j].RolId).Descripcion;
                if(!_RolesTotal.find(R => R.RolId == _Roles[j].RolId)){
                    _RolesTotal.push(_Roles[j]);
                }
            }
        }
        console.log(_RolesTotal);
        return _RolesTotal;
    }
    public getRolSesion(): Rol{
        let Rol = this.Perfil.Sesion.AreaAcademica.Roles.filter(R => R.EsRolPrincipal == true)[0];
        if(Rol){
            return Rol;
        }else{
            return this.Perfil.Sesion.AreaAcademica.Roles[0];
        }
    }
    public setRolSesion(id){
        //this.Perfil.Sesion.AreaAcademica.Roles.find(R => R.EsRolPrincipal == true).EsRolPrincipal = false;
        this.Perfil.Sesion.AreaAcademica.Roles.forEach(x=>{
            x.EsRolPrincipal = false;
        })
        this.Perfil.Sesion.AreaAcademica.Roles.forEach(x=>{
            if(x.RolId == id)
            x.EsRolPrincipal = true;
        })
        //this.Perfil.Sesion.AreaAcademica.Roles.find(R => R.RolId == id).EsRolPrincipal = true;
        //this.selectModule();
        this.savePerfil();
    }


    /******************************************** Areas Academicas *************************************/
    public getAreasAcademicas(): AreaAcademica[]{
        return this.Perfil.Persona.Cuentas[0].AreasAcademicas;
    }
    public setAreaAcademicaSesion(AreaAcademicaId: number): void{
        let aa = this.Perfil.Persona.Cuentas[0].AreasAcademicas.find(AA => AA.AreaAcademicaId == AreaAcademicaId);
        aa.Roles[0].EsRolPrincipal = true;
        this.Perfil.Sesion.AreaAcademica = aa;
        this.savePerfil();
    }
    public getAreaAcademicaSesion(): AreaAcademica {
        console.log(this.Perfil);
        return this.Perfil.Sesion.AreaAcademica;
    }

}
