import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiHttpService } from '../api-http/ApiHttp.service';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  public Alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  screen: {size,height,width,isSmall};

  constructor(
    private api: ApiHttpService,
    private http: HttpClient
  ) {
    this.screen = { size:null,height:null,width:null,isSmall:null }
  }

  getInciso(Pos, Desc?) {
    return this.Alphabet[Pos];
  }
  getPosicionLetter(Pos) {
    return this.Alphabet[Pos];
  }

  // Screen Size
  getScreenSize(w,h) {
    if(w < 576)        return '';
    else if(w < 768)   return 'sm';
    else if(w < 992)   return 'md';
    else if(w < 1200)  return 'lg';
    else if(w < 1400)  return 'xl';
    // else return 'xxl';
  }

  getTextColorForBackground(_Hexadecimal) {
    let BackgroundType = this.lightOrDark(_Hexadecimal);
    switch(BackgroundType) {
      case 'light': return 'black';
      case 'dark':  return 'white';
      default: return null;
    }
  }
  lightOrDark(color) {

    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    }
    else {

        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp>127.5) {

        return 'light';
    }
    else {

        return 'dark';
    }

  }
  Promedio(_Array: any[], _Field: string) {
    return (_Array.map(item => item[_Field]).reduce((prev, next) => prev + next) / _Array.length);
  }
  max(data, attr) {
    const max = data.reduce((prev, current) => (prev[attr] > current[attr]) ? prev : current);
    return max;
  }
  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl);
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader  = new FileReader();
      reader.addEventListener("load", function () {
          resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
  }

  addReactivo() {}
  removeReactivo() {}

  //http://[SERVER]/WebApi/Sistema/ConsultarFecha
  getDateServer() {
    let _URL = this.api.GetUrlApiEndpoint('Sistema','ConsultarFecha');
    //let _Headers = this.api.GetHttpOptions();
    return this.http.post(_URL,{});
    //return this.http.post('Sistema','ConsultarFecha',{});
  }
}
