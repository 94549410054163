import { Persona, PersonaCuentas } from "./Persona";
import * as moment from 'moment';
import { Rol } from "./Rol";
import { AreaAcademica } from "./AreaAcademica";
import { Curso } from "./Curso";

export class Perfil {
    Persona: PersonaCuentas;
    Sesion: Sesion;
    Curso: Curso;

    constructor(p){
        console.log(p);
        this.Persona = new PersonaCuentas(p.Persona);
        this.Sesion = new Sesion(p.Sesion);

        if(p.Curso)
            this.Curso = new Curso(p.Curso);
    }
}

export class Sesion{
    AreaAcademica: AreaAcademica;
    Curso: Curso;
    TiempoRestante: number;
    TiempoInicio;
    TiempoFin;
    Token: string;
    isExpired: boolean;

    constructor(s){
        this.Token = s.Token;
        this.TiempoRestante = s.TiempoRestante;
        this.isExpired = s.isExpired;
        
        if(s.AreaAcademica)
            this.AreaAcademica = s.AreaAcademica;

        if(s.Curso)
            this.Curso = new Curso(s.Curso);

        if(s.TiempoInicio){
            this.TiempoInicio = new Date(s.TiempoInicio); 
        }else{
            this.TiempoInicio = new Date();
        }
        
        if(s.TiempoFin){
            this.TiempoFin = new Date(s.TiempoFin);
        }else{
            this.TiempoFin = new Date(moment().add(s.TiempoRestante,'seconds').format('YYYY-MM-DD HH:mm:ss'));
        }
        
    }
}