export class Actividad{
    ActividadId: number;
    TemaId: number;
    Nombre: string;
    Proposito: string;
    FechaLimite: Date;
    MedioEnvio: number;
    Instrucciones: string;
    Valor: number;
    RestringirEntrega: boolean;
    Posicion: number;
    EnEquipo: boolean;
    Estado: boolean;

    CriteriosEvaluacion: string;
    ActividadExamenes = [];
    

    constructor(A){
        if(A){
            this.ActividadId = A.ActividadId;
            this.TemaId = A.TemaId;
            this.Nombre = A.Nombre;
            this.Proposito = A.Proposito;
            this.FechaLimite = new Date(A.FechaLimite);
            this.MedioEnvio = A.MedioEnvio;
            this.Instrucciones = A.Instrucciones;
            this.Valor = A.Valor;
            this.CriteriosEvaluacion = A.CriteriosEvaluacion;
            this.RestringirEntrega = A.RestringirEntrega;
            this.Posicion = A.Posicion;
            this.EnEquipo = A.EnEquipo;
            this.Estado = A.Estado;

            this.ActividadExamenes = A.ActividadExamenes;
        }
    }
}