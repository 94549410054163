import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { OrderPipe } from 'ngx-order-pipe';
import { Aviso } from 'src/app/models/Aviso/Aviso';
import { AvisoService } from 'src/app/services/Aviso/Aviso.service';
import { DateService } from 'src/app/services/Utils/Date/Date.service';

@Component({
  selector: 'app-avisos-modal',
  templateUrl: './avisos-modal.component.html',
  styleUrls: ['./avisos-modal.component.css']
})
export class AvisosModalComponent implements OnInit {

  public Avisos: Aviso[] = [];
  public AvisoSelected: Aviso;
  public index = 0;
  constructor(
    private AvisoSVC: AvisoService,
    private FechaSVC: DateService,
    private orderPipe: OrderPipe,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    console.log(this.data);
      console.log("Si hay avisos");
      data.Avisos.forEach(A => {
        let _Diff = this.FechaSVC.GetDiff(A.FechaFin,new Date(),'seconds');
        A.Expirado = (_Diff >= 0) ? true : false;
      });
      let _AvisosList = this.data.Avisos.map(A => new Aviso(A));
      let _AvisosUrgentes = this.orderPipe.transform(_AvisosList.filter(A => A.EsUrgente == true), ["AvisoId"], true);
      let _AvisosNormales = this.orderPipe.transform(_AvisosList.filter(A => A.EsUrgente == false), ["AvisoId"], true);// both this.array and this.order are from above example AppComponent
      this.Avisos = _AvisosUrgentes.concat(_AvisosNormales);
      this.AvisoSelected = this.Avisos[0];

  }

  ngOnInit() {
  }

  /*public getAvisos(){
    this.avisos_service.getAvisos(0,true).subscribe(
      result => {
        console.log(result);
        if(!result.Code){
          this.Avisos = result.Avisos.map(A => new Aviso(A));
        }else{
          this.general_service.snackbarError(result.Message,3000);
        }
      },error => {
        console.log(error);
    });
  }*/

  public prev(index?) {}

  public next(index?) {}

  public selectAviso(A){
    this.AvisoSelected = A;
  }
}
