import { TextEditor } from "./utils/TextEditor";
import { Estudiante } from "./Cuenta";

export class ExamenBase{
    ExamenId: number;
    Nombre: string;
    CursoId: number;
    FechaInicio: Date;
    FechaFin: Date;
    EstadoExamenId: number;
    Estado: boolean;

    constructor(E){
        if(E){
            this.ExamenId = E.ExamenId;
            this.CursoId = E.CursoId;
            this.Nombre = E.Nombre;
            this.FechaInicio = E.FechaInicio;
            this.FechaFin = E.FechaFin;
            this.EstadoExamenId = E.EstadoExamenId;
            this.Estado = E.Estado;
        }
    }
}

export class ExamenEvidencia{
    EvidenciaExamenId: number;
    Examen: Examen;
    Estado: boolean;

    constructor(EE){
        if(EE){
            this.EvidenciaExamenId = EE.EvidenciaExamenId;
            this.Examen = new Examen(EE.Examen);
            this.Estado = EE.Estado;
        }
    }
}

export class EstudianteExamen {
    EstudianteExamenId: number;
    CuentaId: number;
    GrupoId: number;
    Estudiante: Estudiante;
    Habilitado: boolean;

    ExamenId: number;
    FechaInicio: Date;
    FechaFin: Date;
    FechaInicioExamen: Date;
    FechaFinExamen: Date;

    CalificacionObtenida: number;
    CalificacionOtorgada: number;

    MotivoCambioCalificacion: string;
    NombreGrupo: string;
    Tiempo: number;
    TipoReactivacionId: number;
    TipoTerminacionId: number;

    Estado: boolean;

    Reactivos: {
      Contestados: number,
      Totales: number;
    }
    constructor(EE){
        if(EE){
            this.EstudianteExamenId = EE.EstudianteExamenId;
            this.CuentaId = EE.CuentaId;
            this.GrupoId = EE.GrupoId;
            this.Habilitado = EE.Habilitado;
            this.ExamenId = EE.ExamenId;
            this.FechaInicio = EE.FechaInicio ? new Date(EE.FechaInicio) : null;
            this.FechaFin = EE.FechaFin ? new Date(EE.FechaFin) : null;
            this.FechaInicioExamen = new Date(EE.FechaInicioExamen);
            this.FechaFinExamen = new Date(EE.FechaFinExamen);
            this.CalificacionObtenida = EE.CalificacionObtenida;
            this.CalificacionOtorgada = EE.CalificacionOtorgada;
            this.MotivoCambioCalificacion = EE.MotivoCambioCalificacion;
            this.NombreGrupo = EE.NombreGrupo;
            this.Tiempo = EE.Tiempo;
            this.TipoReactivacionId = EE.TipoReactivacionId;
            this.TipoTerminacionId = EE.TipoTerminacionId;
            this.Estado = EE.Estado;
            this.Estudiante = EE.Estudiante ? new Estudiante(EE.Estudiante, EE.Estudiante) : null;
            this.Reactivos = EE.Reactivos;

        }
    }
}

export class ReactivosExamenEstudiante{
    Reactivos: ExamenReactivoRespuesta[] = [];
    ReactivosBloquesFaltantes: number;
    ReactivosBloquesTotales: number;
    RespuestasEstudiante: ExamenContestar;
    TiempoRestante: number;

    constructor(REE){
        if(REE){
            this.Reactivos = REE.Reactivos.map(R => new ExamenReactivoRespuesta(R));
            this.ReactivosBloquesFaltantes = REE.ReactivosBloquesFaltantes;
            this.ReactivosBloquesTotales = REE.ReactivosBloquesTotales;
            this.TiempoRestante = REE.TiempoRestante;
            this.RespuestasEstudiante = new ExamenContestar(REE.RespuestasEstudiante);
        }
    }

}

export class Examen extends ExamenBase{
    Instrucciones: string;
    Tiempo: number;
    PuntosAprobatorios: number;
    TipoRetroalimentacionAlumnoId: number;
    TipoTiempoExamenId: number;
    FechaCreacion: Date;
    FechaModificacion: Date;

    Navegable: boolean = false;
    OrdenReactivosAleatorio: boolean = false;
    PorBloque: boolean = false;
    HabilitarExamenIndividual: boolean = false;
    VerDetalleCalificacionPreviaFinalizacion: boolean = false;
    Reactivos = [];
    TipoVinculoExamenId: number;
    VinculoExamenId: number;
    ElementoExamen: any;
    EsVinculado: boolean;

    constructor(E){
        super(E);
        if(E){
            this.Instrucciones = E.Instrucciones;
            this.Tiempo = E.Tiempo;
            this.FechaCreacion = E.FechaCreacion;
            this.FechaModificacion = E.FechaModificacion;
            this.PuntosAprobatorios = E.PuntosAprobatorios;
            this.HabilitarExamenIndividual = E.HabilitarExamenIndividual;
            this.TipoRetroalimentacionAlumnoId = E.TipoRetroalimentacionAlumnoId;
            this.Navegable = E.Navegable;
            this.TipoTiempoExamenId = E.TipoTiempoExamenId;
            this.OrdenReactivosAleatorio = E.OrdenReactivosAleatorio;
            this.PorBloque = E.PorBloque;
            this.VerDetalleCalificacionPreviaFinalizacion = E.VerDetalleCalificacionPreviaFinalizacion;
            this.TipoVinculoExamenId = E.TipoVinculoExamenId;
            this.VinculoExamenId = E.VinculoExamenId;
            this.ElementoExamen = E.ElementoExamen;
            this.EsVinculado = E.EsVinculado;
        }
    }
}

/* ExamenEtapa */
export class ExamenEtapa{
    EtapaExamenId: number;
    EtapaId: number;
    Examen: ExamenBase = new ExamenBase(null);
    Valor: number;
    Posicion: number;
    Estado: boolean;

    constructor(EE){
        if(EE){
            this.EtapaExamenId = EE.EtapaExamenId;
            this.EtapaId = EE.EtapaId;
            this.Examen = new ExamenBase(EE.Examen);
            this.Valor = EE.Valor;
            this.Posicion = EE.Posicion;
            this.Estado = EE.Estado;
        }
    }
}

/* ExamenEvidencia */
export class ExamenElemento{
    EvidenciaExamenId: number;
    ActividadExamenId: number;
    Examen: ExamenBase = new ExamenBase(null);
    Estado: boolean;

    constructor(EE){
        if(EE){
            if(EE.EvidenciaExamenId)this.EvidenciaExamenId = EE.EvidenciaExamenId;
            if(EE.ActividadExamenId)this.ActividadExamenId = EE.ActividadExamenId;
            this.Examen = new ExamenBase(EE.Examen);
            this.Estado = EE.Estado;
        }
    }
}

/* Reactivos */
export class ExamenReactivoRespuesta{
    Reactivo: ExamenReactivo = new ExamenReactivo(null);
    ReactivoRespuestaVerdaderoFalso: ExamenReactivoRespuestaVF;
    ReactivoRespuestaOpcionMultiple = [];
    ReactivoRespuestaMultiple = [];
    ReactivoRespuestaRelacionarDerecha = [];
    ReactivoRespuestaRelacionarIzquierda = [];

    constructor(ERR){
        if(ERR){
            this.Reactivo = new ExamenReactivo(ERR.Reactivo);
            switch(ERR.Reactivo.TipoReactivoId){
                //Verdadero/Falso
                case 1: this.ReactivoRespuestaVerdaderoFalso = new ExamenReactivoRespuestaVF(ERR.ReactivoRespuestaVerdaderoFalso[0] || ERR.ReactivoRespuestaVerdaderoFalso); break;
                //Opcion Multiple
                case 2: this.ReactivoRespuestaOpcionMultiple = ERR.ReactivoRespuestaOpcionMultiple.map(R => new ExamenReactivoRespuestaOM(R)); break;
                //Respuesta Multiple
                case 3: this.ReactivoRespuestaMultiple = ERR.ReactivoRespuestaMultiple.map(R => new ExamenReactivoRespuestaRM(R)); break;
                //Relacionar
                case 4: this.ReactivoRespuestaRelacionarDerecha   = ERR.ReactivoRespuestaRelacionarDerecha.map(R => new ExamenReactivoRespuestaRD(R));
                        this.ReactivoRespuestaRelacionarIzquierda = ERR.ReactivoRespuestaRelacionarIzquierda.map(R => new ExamenReactivoRespuestaRI(R));
                        break;
            }
        }
    }
}


export class ExamenContestar{
    ExamenId: number;
    RespuestaVerdaderoFalso: ExamenRespuestaContestar[] = [];
    RespuestaOpcionMultiple: ExamenRespuestaContestar[] = [];
    RespuestaMultiple: ExamenRespuestaContestar[] = [];
    RespuestaAbierta: ExamenRespuestaContestar[] = [];
    RespuestaRelacionar: ExamenRespuestaContestar[] = [];

    constructor(EC){
        if(EC){
            this.ExamenId = EC.ExamenId;
            this.RespuestaVerdaderoFalso = EC.RespuestaVerdaderoFalso ? EC.RespuestaVerdaderoFalso.map(R => new ExamenRespuestaContestar(R)) : [];
            this.RespuestaOpcionMultiple = EC.RespuestaOpcionMultiple ? EC.RespuestaOpcionMultiple.map(R => new ExamenRespuestaContestar(R)) : [];
            this.RespuestaMultiple = EC.RespuestaMultiple ? EC.RespuestaMultiple.map(R => new ExamenRespuestaContestar(R)) : [];
            this.RespuestaAbierta = EC.RespuestaAbierta ? EC.RespuestaAbierta.map(R => new ExamenRespuestaContestar(R)) : [];
            this.RespuestaRelacionar = EC.RespuestaRelacionar ? EC.RespuestaRelacionar.map(R => new ExamenRespuestaContestar(R)) : [];
        }
    }

}

export class ExamenRespuestaContestar{
    ReactivosId: number;
    Respuesta: any;
    ReactivoRespuestaRelacionarIzquierdaId: number;
    ReactivoRespuestaRelacionarDerechaId: number;

    constructor(ERC){
        if(ERC){
            this.ReactivosId = ERC.ReactivosId;
            this.Respuesta = ERC.Respuesta;
            this.ReactivoRespuestaRelacionarIzquierdaId = ERC.ReactivoRespuestaRelacionarIzquierdaId;
            this.ReactivoRespuestaRelacionarDerechaId = ERC.ReactivoRespuestaRelacionarDerechaId;
        }
    }
}

export class ExamenReactivo {
    ReactivosId: number;
    ExamenId: number;
    TipoReactivoId: number;
    Descripcion: string;
    RetroalimentacionCorrecta: string;
    RetroalimentacionIncorrecta: string;
    Respuesta: ExamenRespuestaContestar;
    Respuestas: ExamenRespuestaContestar[];
    Posicion: number;
    Valor: number;
    Tiempo: number;
    Bloque: number;
    Estado: boolean;

    constructor(ER) {
        if (ER) {
            this.ReactivosId = ER.ReactivosId;
            this.ExamenId = ER.ExamenId;
            this.TipoReactivoId = ER.TipoReactivoId;
            this.Descripcion = new TextEditor().CleanFroalaEditor(ER.Descripcion);
            this.RetroalimentacionCorrecta = ER.RetroalimentacionCorrecta;
            this.RetroalimentacionIncorrecta = ER.RetroalimentacionIncorrecta;
            this.Posicion = ER.Posicion;
            this.Valor = ER.Valor;
            this.Tiempo = ER.Tiempo;
            this.Bloque = ER.Bloque;
            this.Estado = ER.Estado;
            this.Respuesta = new ExamenRespuestaContestar(ER.Respuesta);
        }
    }
}

/* Reactivos Respuestas */
export class ExamenRespuesta{
    ReactivosId: number;
    Estado: boolean;
    Posicion: number;
    EsCorrecta: boolean;

    constructor(ER){
        if(ER){
            this.ReactivosId = ER.ReactivosId;
            this.Posicion = ER.Posicion;
            this.EsCorrecta = ER.EsCorrecta;
            this.Estado = ER.Estado;
        }
    }
}

export class ExamenReactivoRespuestaVF extends ExamenRespuesta{
    ReactivoRespuestaVerdaderoFalsoId: number;
    Respuesta: boolean;
    TipoRespuestaVerdaderoFalsoId: number;

    constructor(ERR_VF){
        if(ERR_VF){
            super(ERR_VF);
            this.ReactivoRespuestaVerdaderoFalsoId = ERR_VF.ReactivoRespuestaVerdaderoFalsoId;
            this.Respuesta = ERR_VF.Respuesta;
            this.TipoRespuestaVerdaderoFalsoId = ERR_VF.TipoRespuestaVerdaderoFalsoId;
        }
    }
}

export class ExamenReactivoRespuestaOM extends ExamenRespuesta{
    ReactivoRespuestaOpcionMultipleId;
    Respuesta: string;

    constructor(ERR_OM){
        if(ERR_OM){
            super(ERR_OM);
            this.ReactivoRespuestaOpcionMultipleId = ERR_OM.ReactivoRespuestaOpcionMultipleId;
            this.Respuesta =  new TextEditor().CleanFroalaEditor(ERR_OM.Respuesta);
        }
    }
}

export class ExamenReactivoRespuestaRM extends ExamenRespuesta{
    ReactivoRespuestaMultipleId: number;
    Respuesta: string;

    constructor(ERR_RM){
        if(ERR_RM){
            super(ERR_RM);
            this.ReactivoRespuestaMultipleId = ERR_RM.ReactivoRespuestaMultipleId;
            this.Respuesta = new TextEditor().CleanFroalaEditor(ERR_RM.Respuesta);
        }
    }
}

export class ExamenReactivoRespuestaRI extends ExamenRespuesta{
    ReactivoRespuestaRelacionarIzquierdaId: number;
    ReactivoRespuestaRelacionarDerechaId: number;
    Izquierda: string;

    constructor(ERR_RI){
        if(ERR_RI){
            super(ERR_RI);
            this.ReactivoRespuestaRelacionarIzquierdaId = ERR_RI.ReactivoRespuestaRelacionarIzquierdaId;
            this.ReactivoRespuestaRelacionarDerechaId = ERR_RI.ReactivoRespuestaRelacionarDerechaId;
            this.Izquierda = new TextEditor().CleanFroalaEditor(ERR_RI.Izquierda);
        }
    }
}

export class ExamenReactivoRespuestaRD extends ExamenRespuesta{
    ReactivoRespuestaRelacionarDerechaId: number;
    Derecha: string;

    constructor(ERR_RD){
        if(ERR_RD){
            super(ERR_RD);
            this.ReactivoRespuestaRelacionarDerechaId = ERR_RD.ReactivoRespuestaRelacionarDerechaId;
            this.Derecha = new TextEditor().CleanFroalaEditor(ERR_RD.Derecha);
        }
    }
}

/* ReactivoRespuestaCalificacion */
export class ReactivoRespuestaCalificacion extends ExamenReactivoRespuesta {
    Calificacion: number;
    RespuestaAbierta: {EjecucionReactivoId: 0, Respuesta: null, ReactivosId: 0, Retroalimentacion: null}
    RespuestaMultiple = [];
    RespuestaOpcionMultiple: {EjecucionReactivoId: 0, Respuesta: 0, ReactivosId: 0, EsCorrecta: false}
    RespuestaRelacionar = []
    RespuestaVerdaderoFalso: {EjecucionReactivoId: 0, Respuesta: true, ReactivosId: 30502, EsCorrecta: true}
    constructor(RRC){
        super(RRC);
        if(RRC){

        }
    }
}

export class RespuestaEjecucion{
    EjecucionReactivoId: number;
    ReactivosId: number;
    EsCorrecta: boolean;
    Respuesta: any; //string | boolean | number
    Retroalimentacion: string;

    constructor(RE){
        if(RE){
            this.EjecucionReactivoId = RE.EjecucionReactivoId;
            this.ReactivosId = RE.ReactivosId;
            this.EsCorrecta = RE.EsCorrecta;
            this.Respuesta = RE.Respuesta;
            this.Retroalimentacion = RE.Retroalimentacion;
        }
    }
}
