import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Aviso } from 'src/app/shared/DTO/Aviso';

@Component({
  selector: 'app-aviso-view',
  templateUrl: './aviso-view.component.html',
  styleUrls: ['./aviso-view.component.css']
})
export class AvisoVistaComponent implements OnInit {

  public Aviso: Aviso;

  constructor(
    private dialogRef: MatDialogRef<AvisoVistaComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.Aviso = this.data;
    console.log(this.Aviso);

  }

}
