import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class DateService {

  private _Meses = [
    {Id: 1, Valor: "01", NombreCorto:'ENE', Nombre:"Enero"},
    {Id: 2, Valor: "02", NombreCorto:'FEB', Nombre:"Febrero"},
    {Id: 3, Valor: "03", NombreCorto:'MAR', Nombre:"Marzo"},
    {Id: 4, Valor: "04", NombreCorto:'ABR', Nombre:"Abril"},
    {Id: 5, Valor: "05", NombreCorto:'MAY', Nombre:"Mayo"},
    {Id: 6, Valor: "06", NombreCorto:'JUN', Nombre:"Junio"},
    {Id: 7, Valor: "07", NombreCorto:'JUL', Nombre:"Julio"},
    {Id: 8, Valor: "08", NombreCorto:'AGO', Nombre:"Agosto"},
    {Id: 9, Valor: "09", NombreCorto:'SEP', Nombre:"Septiembre"},
    {Id: 10,Valor: "10", NombreCorto:'OCT', Nombre:"Octubre"},
    {Id: 11,Valor: "11", NombreCorto:'NOV', Nombre:"Noviembre"},
    {Id: 12,Valor: "12", NombreCorto:'DIC', Nombre:"Diciembre"},
];

  private _FormatosFecha = [
    { id: 0, format:'' },
    { id: 1, format:'DD/MMMM/YYYY' },
    { id: 2, format:'DD/MMM/YYYY' },
    { id: 3, format:'DD/MM/YYYY' },
    { id: 4, format:'YYYY/MMMM/DD' },
    { id: 5, format:'YYYY/MMM/DD' },
    { id: 6, format:'YYYY/MM/DD' },
  ];
  private _FormatosHora = [
    { id: 0, format:'' },
    { id: 1, format:'hh:mm a' },
    { id: 2, format:'HH:mm' },
    { id: 3, format:'hh:mm:ss a' },
    { id: 4, format:'HH:mm:ss' }
  ];

  constructor() { }

  public moment(date?) {
    return moment(date);
  }

  public getDateTime(Fecha,Hora) {
    console.log(Fecha);
    console.log(Hora);
    let FechaFormat = moment(Fecha).format('DD/MM/YYYY');
    let HoraFormat = moment(Fecha).format('HH:mm:ss');
    return `${FechaFormat} ${HoraFormat}`;
  }

  public getFormatoFecha(id: number): string {
    return this._FormatosFecha.find(F => F.id === id).format;
  }
  public getFormatoHora(id: number): string {
    return this._FormatosHora.find(F => F.id === id).format;
  }

  public FechaFormatoMoment(D: Date, Format: string): string {
    return moment(D).format(Format);
  }

  public FechaFormato(D: Date, DateFormatId: number, TimeFormatId: number): string {
    return this.FechaFormatoMoment(D, this.getFormatoFecha(DateFormatId) + ' ' + this.getFormatoHora(TimeFormatId));
  }

  public Locale(dateString){
    dateString = dateString.toString();
    dateString = dateString.split(' ').slice(0, 5).join(' ');
    return dateString;
  }

  public DatesDiffSeconds(DateStart, DateEnd){
    let Date1 = moment(DateStart);
    let Date2 = moment(DateEnd);
    let Diff = Date2.diff(Date1, "seconds");
    return Diff;
  }
  public SameDay(Date1,Date2){
    let validation = moment(Date1).isSame(Date2, 'day');
    return validation
  }
  public SameWeek(Date1,Date2){
    let validation = moment(Date1).isSame(Date2, 'week');
    return validation
  }

  public DatesDiff(FechaInicio?, FechaFin?, Text?) {
    const F1 = moment(FechaInicio ? FechaInicio : new Date());
    const F2 = moment(FechaFin ? FechaFin : new Date());
    console.log(F1,F2);
    const Diferencia = F2.diff(F1, 'seconds');
    let Numero;
    let Unidad;
    if (Diferencia < 60) {
        Numero = Diferencia % 60;
        Unidad = 'min';
    } else if (Diferencia < 3600 ) {
      Numero = Diferencia % 60;
      if (Numero === 1) Unidad = 'min';
      else Unidad = 'min';
    } else if (Diferencia < 86400) {
      Numero = Diferencia % 3600;
      if (Numero === 1) Unidad = 'hora';
      else Unidad = 'horas';
    } else if (Diferencia < 604800) {
      Numero = Diferencia % 86400;
      if (Numero == 1) Unidad = 'dia';
      else Unidad = 'dias';
    } else if (Diferencia < 31557600) {
      Numero = Diferencia % 604800;
      console.log(Numero);
      if (Numero == 1) Unidad = 'semana';
      else Unidad = 'semanas';
    } else {
      Numero = Diferencia % 31557600;
      if (Numero == 1) Unidad = 'año';
      else Unidad = 'años';
    }
    let prefix = Text ? Text : 'Hace ';
    const D = prefix + Numero + ' ' + Unidad;
    return D;

  }
  public GetDiff(Date1, Date2, Type) {
    return moment(Date2).diff(moment(Date1),Type);
  }
  public DateToLocale(dateString){
    //console.log(dateString);
    dateString = dateString.toString();
    dateString = dateString.split(' ').slice(0, 5).join(' ');
    //console.log(dateString);
    return dateString;
  }

  /******************** Month **********************/
  public get Meses(){
    let M = this._Meses;
    return M;
  }

  /******************* DatePicker ********************/
  public DatePickerSettings(opt) {
    let Settings = {
      BigBanner: true,
      timePicker: true,
      format: 'dd-MM-yyyy hh:mm a',
      defaultOpen: true,
      closeOnSelect: true
    };
    switch (opt) {
      case 1: break;
      case 2: Settings.BigBanner = false;
              Settings.defaultOpen = false;
              break;
    }
    return Settings;
  }

}
