import { TextEditor } from "./utils/TextEditor";

export class BancoReactivos{
    BancoReactivosId: number;
    Nombre: string;
    Descripcion: string;
    CuentaId: number;
    FechaCreacion;
    Estado: boolean;

    EsCompartido: boolean;
    CompartidoId: number;
    

    constructor(BR){
        if(BR){
            this.BancoReactivosId = BR.BancoReactivosId;
            this.Nombre = BR.Nombre;
            this.Descripcion = BR.Descripcion;
            this.CuentaId = BR.CuentaId;
            this.FechaCreacion = new Date(BR.FechaCreacion);
            this.Estado = BR.Estado;

            this.EsCompartido = BR.EsCompartido;
            this.CompartidoId = BR.CompartidoId;
        }
    }
}

//Reactivo
//ReactivoRespuestaOpcionMultiple
//ReactivoRespuestaMultiple
//ReactivoRespuestaRelacionarDerecha
//ReactivoRespuestaRelacionarIzquierda
//ReactivoRespuestaVerdaderoFalso

export class Reactivo{
    ReactivosId: number;
	BancoReactivosId: number;
	TipoReactivoId: number;
	Posicion: number;
	Descripcion: string;
	RetroalimentacionCorrecta: string = "";
    RetroalimentacionIncorrecta: string = "";
    Estado: boolean;

    constructor(BR){
        if(BR){
            this.ReactivosId = BR.ReactivosId;
            this.BancoReactivosId = BR.BancoReactivosId;
            this.TipoReactivoId = BR.TipoReactivoId;
            this.Posicion = BR.Posicion;
            this.Descripcion = new TextEditor().CleanFroalaEditor(BR.Descripcion);
            this.RetroalimentacionCorrecta = BR.RetroalimentacionCorrecta;
            this.RetroalimentacionIncorrecta = BR.RetroalimentacionIncorrecta;
            this.Estado = BR.Estado;
        }
    }
}

export class BReactivo{
    
    Reactivo: Reactivo = new Reactivo(null);
    ReactivoRespuestaVerdaderoFalso = [];
    ReactivoRespuestaOpcionMultiple = [];
    ReactivoRespuestaMultiple = [];
    ReactivoRespuestaRelacionarDerecha = [];
    ReactivoRespuestaRelacionarIzquierda = [];
    
    constructor(BR){
        if(BR){
            this.Reactivo = new Reactivo(BR.Reactivo);

            switch(BR.Reactivo.TipoReactivoId){
                //Verdadero/Falso
                case 1: this.ReactivoRespuestaVerdaderoFalso = BR.ReactivoRespuestaVerdaderoFalso.map(R => new BReactivoRespuestaVF(R)); break;
                //Opcion Multiple
                case 2: this.ReactivoRespuestaOpcionMultiple = BR.ReactivoRespuestaOpcionMultiple.map(R => new BReactivoRespuestaOpcionMult(R)); break;
                //Respuesta Multiple
                case 3: this.ReactivoRespuestaMultiple = BR.ReactivoRespuestaMultiple.map(R => new BReactivoRespuestaResMult(R)); break;
                //Relacionar
                case 4: this.ReactivoRespuestaRelacionarDerecha   = BR.ReactivoRespuestaRelacionarDerecha.map(R => new BReactivoRespuestaRelacionDer(R));
                        this.ReactivoRespuestaRelacionarIzquierda = BR.ReactivoRespuestaRelacionarIzquierda.map(R => new BReactivoRespuestaRelacionIzq(R));
                        break;
            }
        }
    }
}

export class BReactivoRespuesta{
    ReactivosId: number;
    Estado: boolean;
    Posicion: number;
    EsCorrecta: boolean;

    constructor(BRR){
        if(BRR){
            this.ReactivosId = BRR.ReactivosId;
            this.Posicion = BRR.Posicion;
            this.EsCorrecta = BRR.EsCorrecta;
            this.Estado = BRR.Estado;
        }
    }
}

export class BReactivoRespuestaVF extends BReactivoRespuesta{
    ReactivoRespuestaVerdaderoFalsoId: number;
    Respuesta: boolean;
    TipoRespuestaVerdaderoFalsoId: number;

    constructor(BRR_VF){
        if(BRR_VF){
            super(BRR_VF);
            this.ReactivoRespuestaVerdaderoFalsoId = BRR_VF.ReactivoRespuestaVerdaderoFalsoId;
            this.Respuesta = BRR_VF.Respuesta;
            this.TipoRespuestaVerdaderoFalsoId = BRR_VF.TipoRespuestaVerdaderoFalsoId;
        }
    }
}
export class BReactivoRespuestaOpcionMult extends BReactivoRespuesta{
    ReactivoRespuestaOpcionMultipleId;
    Respuesta: string;

    constructor(BRR_OM){
        if(BRR_OM){
            super(BRR_OM);
            this.ReactivoRespuestaOpcionMultipleId = BRR_OM.ReactivoRespuestaOpcionMultipleId;
            this.Respuesta = new TextEditor().CleanFroalaEditor(BRR_OM.Respuesta);
        }
    }
}
export class BReactivoRespuestaResMult extends BReactivoRespuesta{
    ReactivoRespuestaMultipleId: number;
    Respuesta: string;

    constructor(BRR_RM){
        if(BRR_RM){
            super(BRR_RM);
            this.ReactivoRespuestaMultipleId = BRR_RM.ReactivoRespuestaMultipleId;
            this.Respuesta = new TextEditor().CleanFroalaEditor(BRR_RM.Respuesta);
        }
    }
}
export class BReactivoRespuestaRelacionDer extends BReactivoRespuesta{
    ReactivoRespuestaRelacionarDerechaId: number;
    Derecha: string;

    constructor(BRR_RD){
        if(BRR_RD){
            super(BRR_RD);
            this.ReactivoRespuestaRelacionarDerechaId = BRR_RD.ReactivoRespuestaRelacionarDerechaId;
            this.Derecha = new TextEditor().CleanFroalaEditor(BRR_RD.Derecha);
        }
    }
}
export class BReactivoRespuestaRelacionIzq extends BReactivoRespuesta{
    ReactivoRespuestaRelacionarIzquierdaId: number;
    ReactivoRespuestaRelacionarDerechaId: number;
    Izquierda: string;

    constructor(BRR_RI){
        console.log(BRR_RI);
        if(BRR_RI){
            super(BRR_RI);
            this.ReactivoRespuestaRelacionarIzquierdaId = BRR_RI.ReactivoRespuestaRelacionarIzquierdaId;
            this.ReactivoRespuestaRelacionarDerechaId = BRR_RI.ReactivoRespuestaRelacionarDerechaId;
            this.Izquierda = new TextEditor().CleanFroalaEditor(BRR_RI.Izquierda);
        }
    }
}