import { TextEditor } from "./utils/TextEditor";

export class Encuesta {
    EncuestaId: number;
    Titulo: string;
    Descripcion: string;
    FechaInicio: Date;
    FechaFin: Date;
    Ejecuciones: number;
    Estatus;

    RolId: number;
    Propia: boolean;
    BloquearCurso: boolean;
    TipoDestinoId: number;
    EstadoEncuestaId: number;

    Estado: boolean;

    Destinos: EncuestaDestino[] = [];
    RolesDirigido: EncuestaRol[] = [];
    Reactivos = [];

    constructor(E,Tipo) {
        if (E) {
            this.EncuestaId = E.EncuestaId;
            this.Titulo = E.Titulo;
            this.Descripcion = E.Descripcion;
            this.FechaInicio = new Date(E.FechaInicio);
            this.FechaFin = new Date(E.FechaFin);
            this.Ejecuciones = E.Ejecuciones;

            this.RolId = E.RolId;
            this.Propia = E.Propia;
            this.BloquearCurso = E.BloquearCurso;
            this.TipoDestinoId = E.TipoDestinoId;
            this.EstadoEncuestaId = E.EstadoEncuestaId;

            this.Estado = E.Estado;

            if(Tipo == 1){
                if(E.Destinos)      this.Destinos = E.Destinos;
                if(E.RolesDirigido) this.RolesDirigido = E.RolesDirigido;
                if(E.Reactivos)     this.Reactivos = E.Reactivos.map(R => new EncuestaReactivo(R));
            }else if(Tipo == 2){
                if(E.Reactivos)     this.Reactivos = E.Reactivos.map(R => new EncuestaReactivoEjecucion(R));
            }

        }
    }
}

export class EncuestaDestino {
    EncuestaDestinoId: number;
    DestinoId: number;
    Estado: boolean;

    constructor(D){
        if(D){
            this.EncuestaDestinoId = D.EncuestaDestinoId;
            this.DestinoId = D.DestinoId;
            this.Estado = D.Estado;
        }
    }
}

export class EncuestaRol {
    EncuestaRolId: number;
    RolId: number;
    Estado: boolean;

    constructor(R){
        this.EncuestaRolId = R.EncuestaRolId,
        this.RolId = R.RolId;
        this.Estado = R.Estado;
    }
}



//Reactivo
//ReactivoRespuestaOpcionMultiple
//ReactivoRespuestaMultiple
//ReactivoRespuestaRelacionarDerecha
//ReactivoRespuestaRelacionarIzquierda
//ReactivoRespuestaVerdaderoFalso


/******************** Encuesta Reactivos ************************/
export class EncuestaReactivo {
    EncuestaReactivoId: number;
    EncuestaId: number;
    Descripcion: string;
    TipoReactivoId: number;
    CantidadRespuestas: number;
    Posicion: number;
    Estado: boolean;

    Respuestas: EncuestaRespuesta[] = [];
    RespuestaVerdaderoFalso: EncuestaReactivoRespuestaVF;


    constructor(RE){
        if(RE){
            this.EncuestaReactivoId = RE.EncuestaReactivoId;
            this.EncuestaId = RE.EncuestaId;
            this.Descripcion = new TextEditor().CleanFroalaEditor(RE.Descripcion);
            this.TipoReactivoId = RE.TipoReactivoId;
            this.CantidadRespuestas = RE.CantidadRespuestas;
            this.Posicion = RE.Posicion;
            this.Estado = RE.Estado;

            this.Respuestas = RE.Respuestas.map(R => new EncuestaRespuesta(R));
            this.RespuestaVerdaderoFalso = new EncuestaReactivoRespuestaVF(RE.RespuestaVerdaderoFalso);
        }
    }
}

/******************** Encuesta Reactivos Respuestas ************************/
export class EncuestaRespuesta{
    EncuestaReactivoRespuestaId: number;
    Descripcion: number;
    Posicion: number;
    Estado: boolean;

    constructor(ResE){
        if(ResE){
            this.EncuestaReactivoRespuestaId = ResE.EncuestaReactivoRespuestaId;
            this.Descripcion = ResE.Descripcion;
            this.Posicion = ResE.Posicion;

            if(ResE.Estado != null){this.Estado = ResE.Estado }
            else{ this.Estado = true }
        }
    }
}
export class EncuestaReactivoRespuestaVF extends EncuestaRespuesta{
    EncuestaReactivoRespuestaVerdaderoFalsoId: number;
    TipoRespuestaVerdaderoFalso: TipoRespuestaVerdaderoFalso;
    Estado: boolean;

    constructor(BRR_VF){
        if(BRR_VF){
            super(BRR_VF);
            this.EncuestaReactivoRespuestaVerdaderoFalsoId = BRR_VF.EncuestaReactivoRespuestaVerdaderoFalsoId;
            this.TipoRespuestaVerdaderoFalso = new TipoRespuestaVerdaderoFalso(BRR_VF.TipoRespuestaVerdaderoFalso);
            this.Estado = BRR_VF.Estado;
        }
    }
}
export class TipoRespuestaVerdaderoFalso{
    TipoRespuestaVerdaderoFalsoId: number;
    NombreVerdadero: string;
    NombreFalso: string;
    Estatus: false;

    constructor(BRR_VF){
        if(BRR_VF){
            this.TipoRespuestaVerdaderoFalsoId = BRR_VF.TipoRespuestaVerdaderoFalsoId;
            this.NombreVerdadero = BRR_VF.NombreVerdadero;
            this.NombreFalso = BRR_VF.NombreFalso;
            this.Estatus = BRR_VF.Estatus;
        }
    }
}


/******************** Encuesta Ejecucion ************************/

export class EncuestaReactivoEjecucion extends EncuestaReactivo{
    EjecucionReactivoAbierto: EjecucionReactivoAbierto;
    EjecucionReactivoVerdaderoFalso: EjecucionReactivoVerdaderoFalso;
    EjecucionReactivoOpcionMultiple: EjecucionReactivoOpcionMultiple
    EjecucionReactivoRespuestaMultipleDTO: EjecucionReactivoRespuestaMultipleDTO [] = [];

    constructor(R){

        super(R);
        if(R){
            this.EjecucionReactivoAbierto = R.EjecucionReactivoAbierto;
            this.EjecucionReactivoVerdaderoFalso = R.EjecucionReactivoVerdaderoFalso;
            this.EjecucionReactivoOpcionMultiple = R.EjecucionReactivoOpcionMultiple;
            this.EjecucionReactivoRespuestaMultipleDTO = R.EjecucionReactivoRespuestaMultipleDTO.map(ER => new EjecucionReactivoRespuestaMultipleDTO(ER));
        }
    }
}

export class EjecucionReactivoVerdaderoFalso{
    EjecucionReactivoVerdaderoFalsoId: number;
    EjecucionReactivo: boolean;
    Estado: boolean;

    constructor(R){
        if(R){
            this.EjecucionReactivoVerdaderoFalsoId = R.EjecucionReactivoVerdaderoFalsoId;
            this.EjecucionReactivo = R.EjecucionReactivo;
            this.Estado = R.Estado;
        }
    }
}
export class EjecucionReactivoOpcionMultiple{
    EjecucionReactivoOpcionMultipleId: number;
    EncuestaReactivoRespuestaId: boolean;
    Estado: boolean;

    constructor(R){
        if(R){
            this.EjecucionReactivoOpcionMultipleId = R.EjecucionReactivoOpcionMultipleId;
            this.EncuestaReactivoRespuestaId = R.EncuestaReactivoRespuestaId;
            this.Estado = R.Estado;
        }
    }
}
export class EjecucionReactivoRespuestaMultipleDTO{
    EjecucionReactivoRespuestaMultipleId: number;
    EncuestaReactivoRespuestaId: boolean;
    Estado: boolean;

    constructor(R){
        if(R){
            this.EjecucionReactivoRespuestaMultipleId = R.EjecucionReactivoRespuestaMultipleId;
            this.EncuestaReactivoRespuestaId = R.EncuestaReactivoRespuestaId;
            this.Estado = R.Estado;
        }
    }
}
export class EjecucionReactivoAbierto{
    EjecucionReactivoAbiertoId: number;
    EjecucionReactivo: boolean;
    Estado: boolean;

    constructor(R){
        if(R){
            this.EjecucionReactivoAbiertoId = R.EjecucionReactivoAbiertoId;
            this.EjecucionReactivo = R.EjecucionReactivo;
            this.Estado = R.Estado;
        }
    }
}


/* Encuesta Resultados */

export class EncuestaReactivoResultado{
    EncuestaReactivoRespuestaId: number;
    Descripcion: string;
    Cantidad: number;
    Posicion: number;

    constructor(ERR){
        if(ERR){
            this.EncuestaReactivoRespuestaId = ERR.EncuestaReactivoRespuestaId;
            this.Descripcion = ERR.Descripcion;
            this.Cantidad = ERR.Cantidad;
            this.Posicion = ERR.Posicion;
        }
    }
}
