import { PersonaRolId } from "./Persona";
import { Cuenta, Profesor, Estudiante } from "./Cuenta";
import { Grupo } from "./Grupo";
import { Estructura } from "./Estructura";
import { Recursos } from "./Recurso";
import { Modalidad } from "./Modalidad";
import { Compromiso } from "./Compromiso";
import { Foro } from "./Foro";

export class Curso {
    CursoId: number;
    Nombre: string;
    Alias: string;
    AreaAcademicaId: number;
    Grupos: Grupo[] = [];
    Estudiantes: Estudiante[] = [];
    Profesores: Profesor[] = [];
    FechaInicio;
    FechaFin;
    Modalidad: Modalidad = new Modalidad(null);
    ModalidadId: number;
    Compromiso: Compromiso;
    ModeloId: number;
    ProgramaId: number;
    EnSIASE: boolean;
    Replicado: boolean;
    Disponible: boolean;
    Color: string;
    Estado: boolean;

    Bienvenida: string;

    Estructura: Estructura;
    Recursos: Recursos;
    Foro: Foro;

    //DEMO
    HEXA: string;
    Imagen: CursoImagen;
    ImagenId: number;
    EsMaster: boolean;
    EsReplicable: boolean;
    RelacionarRubricaCompetencia: boolean;
    Replicable: boolean;
    constructor(C) {
        if(C != null){
            this.CursoId = C.CursoId;
            this.Nombre = C.Nombre;
            this.Alias = C.Alias;
            this.AreaAcademicaId = C.AreaAcademicaId;
            this.FechaInicio = new Date(C.FechaInicio);
            this.FechaFin = new Date(C.FechaFin);
            this.Modalidad = new Modalidad(C.Modalidad);
            this.ModalidadId = C.ModalidadId;
            this.ModeloId = C.ModeloId;
            this.ProgramaId = C.ProgramaId;
            this.EnSIASE = C.EnSIASE;
            this.Replicado = C.Replicado;
            this.Color = C.Color;
            this.Disponible = C.Disponible;

            if(C.Estado)
            this.Estado = C.Estado;

            //Propiedades Opcionales
            if(C.Bienvenida && C.Bienvenida != "")
                this.Bienvenida = C.Bienvenida;
            if(C.Compromiso && C.Compromiso.Descripcion != null)
                this.Compromiso = new Compromiso(C.Compromiso);

            //Listados
            if(C.Profesores)
                this.Profesores = C.Profesores.map(P => new Profesor(P,null));
            if(C.Estudiantes)
                this.Estudiantes = C.Estudiantes.map(P => new Estudiante(P,null));
            if(C.Grupos)
                this.Grupos = C.Grupos.map(G => new Grupo(G));
            if(C.Estructura)
                this.Estructura = new Estructura(C.Estructura);
            if(C.Recursos)
                this.Recursos = new Recursos(C.Recursos);
            if(C.Foro)
                this.Foro = new Foro(C.Foro);
            if(C.HEXA)
                this.HEXA = C.HEXA;
            if(C.ImagenId)
                this.ImagenId = C.ImagenId;
            if(C.Imagen)
                this.Imagen = new CursoImagen(C.Imagen);

            if(C.EsMaster)
                this.EsMaster = C.EsMaster;

            if(C.RelacionarRubricaCompetencia)
                this.RelacionarRubricaCompetencia = C.RelacionarRubricaCompetencia;

                this.EsReplicable = C.EsReplicable;
            if(C.Replicable)
                this.Replicable = C.Replicable;
        }
    }
}
export class CursoImagen {
  ImagenId: number;
  CarpetaImagen: string;
  Nombre: string;
  NombreBanner: string;
  NombrePreview: string;
  Ruta: string;
  constructor(I) {
    this.ImagenId = I.ImagenId;
    this.CarpetaImagen = I.CarpetaImagen;
    this.Nombre = I.Nombre;
    this.NombreBanner = I.NombreBanner;
    this.NombrePreview = I.NombrePreview;
    this.Ruta = I.Ruta;
  }
}

export class CursoGeneral{
    CursoId: number;
    Nombre: string;
    Alias: string;
    AreaAcademicaId: number;
    EnSIASE: boolean;

    constructor(C){
        if(C){
            this.CursoId = C.CursoId;
            this.Nombre = C.Nombre;
            this.Alias = C.Alias;
            this.AreaAcademicaId = C.AreaAcademicaId;
            this.EnSIASE = C.EnSIASE;
        }
    }
}

export class CursoBase{
    CursoId: number;
    Nombre: string;
    Alias: string;
    AreaAcademicaId: number;
    EnSIASE: boolean;
    ModeloId: number;
    Modalidad: Modalidad = new Modalidad(null);
    FechaInicio;
    FechaFin;
    Profesores = [];
    EsReplicable;
    Replicable;

    constructor(C){
        if(C){
            this.CursoId = C.CursoId;
            this.Nombre = C.Nombre;
            this.Alias = C.Alias;
            this.AreaAcademicaId = C.AreaAcademicaId;
            this.EnSIASE = C.EnSIASE;
            this.ModeloId = C.ModeloId;
            this.Modalidad = new Modalidad(C.Modalidad);;
            this.FechaInicio = new Date(C.FechaInicio);
            this.FechaFin = new Date(C.FechaFin);
            this.Profesores = C.Profesores;
            this.EsReplicable = C.EsReplicable;
            this.Replicable = C.Replicable;
        }
    }
}

export class CursoLista{
    Alias: string;
    CursoId: number;
    Disponible: boolean;
    Nombre: string;
    Profesores: Profesor[] = [];

    constructor(C){
        if(C){
            this.Alias = C.Alias;
            this.CursoId = C.CursoId;
            this.Nombre = C.Nombre;
            this.Disponible = C.Disponible;
            this.Profesores = C.Profesores.map(P => new Profesor(P,null));
        }
    }
}

export class CursoCompartir{

}

export class UnidadAprendizajeDisciplinar{
    UnidadAprendizajeId: number;
    Nombre: string;
    CampoDisciplinarId: number;
    CampoDisciplinar: string;
    PlanEstudioId: number;
    Semestre: number;
    Estado: boolean;
    constructor(UAD){
        if(UAD){
            this.UnidadAprendizajeId = UAD.UnidadAprendizajeId;
            this.Nombre = UAD.Nombre;
            this.CampoDisciplinarId = UAD.CampoDisciplinarId;
            this.CampoDisciplinar = UAD.CampoDisciplinar;
            this.PlanEstudioId = UAD.PlanEstudioId;
            this.Semestre = UAD.Semestre;
            this.Estado = UAD.Estado;
        }
    }

}

export class CursoReplica{
    CursoDestinoId: number;
    RespetarFechas: boolean;
    BloquearContenidoPrograma: boolean;
    BloquearContenidoEstructura: boolean;
    ContenidoCurso: CursoReplicaContenido [];
    constructor(C){
        if(C){
            this.CursoDestinoId = C.CursoDestinoId;
            this.RespetarFechas = C.RespetarFechas;
            this.BloquearContenidoPrograma = C.BloquearContenidoPrograma;
            this.BloquearContenidoEstructura = C.BloquearContenidoEstructura;
            this.ContenidoCurso = C.ContenidoCurso.map(CC => new CursoReplicaContenido(CC));
        }
    }
}

export class CursoReplicaContenido{
    ContenidoCursoId: number;
    CategoriaId: number;
    SeReplica:  boolean;
    ContenidoCursoRecurso;
    //ContenidoCursoRecurso:
    constructor(CC){
        this.ContenidoCursoId = CC.ContenidoCursoId;
        this.CategoriaId = CC.CategoriaId;
        this.SeReplica = CC.SeReplica;
        this.ContenidoCursoRecurso = CC.ContenidoCursoRecurso
    }
}

/*
export class CursoReplicaContenidoRecurso{
    ContenidoCursoRecursoId: number;
    TipoRecursoId: number;
    SeReplica: boolean;
}*/

