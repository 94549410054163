export class Coevaluacion{
    CoevaluacionId: number
    Titulo: string;
    Instrucciones: string;
    EvidenciaId: number;
    TipoCoevaluacionId: number;
    Criterios: CoevaluacionCriterio[] = [];
    Estado: boolean;

    constructor(C){
        if(C){
            this.CoevaluacionId = C.CoevaluacionId;
            this.Titulo = C.Titulo;
            this.Instrucciones = C.Instrucciones;
            this.EvidenciaId = C.EvidenciaId;
            this.TipoCoevaluacionId = C.TipoCoevaluacionId;
            this.Estado = C.Estado;
            if(C.Criterios){
                this.Criterios = C.Criterios.map(CC => new CoevaluacionCriterio(CC));
            }
        }
    }
}

export class CoevaluacionCriterio{
    CriterioId: number;
    Descripcion: string;
    CoevaluacionId: number;
    Posicion: number;
    Calificaciones = [];
    Estado: boolean;

    constructor(CC){
        if(CC){
            this.CriterioId = CC.CriterioId;
            this.Descripcion = CC.Descripcion;
            this.CoevaluacionId = CC.CoevaluacionId;
            this.Posicion = CC.Posicion;
            this.Estado = CC.Estado;
        }
    }
}

export class CriterioCalificacion{
    CriterioCalificacionId: number;
    CriterioId: number;
    Calificacion: number;
    Calificaciones = [];
    Criterio: CoevaluacionCriterio;
    Estado: boolean;
    constructor(CC){
        if(CC){
            this.CriterioCalificacionId = CC.CriterioCalificacionId;
            this.CriterioId = CC.CriterioId;
            this.Calificacion = CC.Calificacion;
            this.Criterio = new CoevaluacionCriterio(CC.Criterio);
            this.Estado = CC.Estado;

            this.Calificaciones = this.Calificaciones;
        }
    }
}
