import { Modalidad } from "./Modalidad";
import { Compromiso } from "../Compromiso/Compromiso";
import { Estudiante } from "../Persona/Estudiante";
import { Profesor } from "../Persona/Profesor";
import { Grupo, GrupoEstudiantes } from "./Grupo";
import { Estructura } from "../Estructura/Estructura";
import { Foro } from "../Foro/Foro";
import { Recursos } from "../Recurso/Recursos";
import { CursoImagen } from "./CursoImagen";

export class CursoBase {
    CursoId: number;
    Nombre: string;
    Alias: string;
    AreaAcademicaId: number;
    FechaInicio: any;
    FechaFin: any;
    ModeloId: number;
    EnSIASE: boolean;
    EsReplicable;
    Replicable;
    TipoCursoId: number;

    constructor(C){
      if(C){
        this.CursoId = C.CursoId;
        this.Nombre = C.Nombre;
        this.Alias = C.Alias;
        this.AreaAcademicaId = C.AreaAcademicaId;
        this.FechaInicio = new Date(C.FechaInicio);
        this.FechaFin = new Date(C.FechaFin);
        this.ModeloId = C.ModeloId;
        this.EnSIASE = C.EnSIASE;
        this.EsReplicable = C.EsReplicable;
        this.Replicable = C.Replicable;
        this.TipoCursoId = C.TipoCursoId;

      }
    }
}

export class Curso extends CursoBase{
  Grupos: GrupoEstudiantes[] = [];
  Estudiantes: Estudiante[] = [];
  Profesores: Profesor[] = [];
  Modalidad: Modalidad = new Modalidad(null);
  ModalidadId: number;
  Compromiso: Compromiso;
  ProgramaId: number;
  Replicado: boolean;
  Disponible: boolean;
  Color: string;
  Estado: boolean;
  Bienvenida: any;
  Estructura: Estructura;
  Recursos: Recursos;
  Foro: Foro;
  Imagen: any;
  EsMaster: boolean;
  RelacionarRubricaCompetencia: boolean;
  EsReplicable: boolean;
  Replicable: boolean;
  TipoCursoId: number;
  CarpetaId: number;
  private _Profesor: Profesor;

  constructor(C, CarpetaId?) {
    super(C);
    if (C) {
      this.CarpetaId = CarpetaId;
      this.Grupos = C.Grupos ? C.Grupos.map(G => new GrupoEstudiantes(G)) : [];
      this.Estudiantes = C.Estudiantes ? C.Estudiantes.map(E => new Estudiante(E)) : [];
      this.Profesores = C.Profesores ? C.Profesores.map(P => new Profesor(P)) : [] ;
      this.Modalidad = new Modalidad(C.Modalidad);
      this.Compromiso = new Compromiso(C.Compromiso);
      this.ModalidadId = C.ModalidadId;
      this.ProgramaId = C.ProgramaId;
      this.Replicado = C.Replicado;
      this.Disponible = C.Disponible;
      this.Color = C.Color;
      this.Estado = C.Estado;
      this.Bienvenida = C.Bienvenida;
      this.Estructura = C.Estructura ? C.Estructura : null;
      this.Recursos = C.Recursos ? C.Recursos : null;
      this.Foro = C.Foro ? C.Foro : null;
      this.Imagen = C.Imagen ? new CursoImagen(C.Imagen) : null;
      this.EsReplicable = C.EsReplicable;
      this.Replicable = C.Replicable;
      this.RelacionarRubricaCompetencia = C.RelacionarRubricaCompetencia;
      this.EsMaster = C.EsMaster;
      this.TipoCursoId = C.TipoCursoId;
    }
  }

  get Profesor() {
    this._Profesor = this.findProfesor();
    return this._Profesor;
  }
  set Profesor(P: Profesor) {
    this._Profesor = P;
  }
  findProfesor() {
    var PT = this.Profesores.filter(P => P.RolId == 2);
    var PA = this.Profesores.filter(P => P.RolId == 3);
    if(PT.length > 0) {
      return PT[0];
    } else if(PA.length > 0) {
      return PA[0];
    } else {
      return null;
    }
  }
}


