import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { request } from 'http';
import { SesionService } from '../services/Sesion/Sesion.service';

@Injectable({
  providedIn: 'root'
})
export class SistemaInterceptorService implements HttpInterceptor {

  constructor(
    private SesionSVC: SesionService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('<------------- Sistema Interceptor ----------------->');
    console.log(req.headers);
    let _headers = req.headers.append('SistemaId',this.SesionSVC.SesionStorage.Sesion.SistemaId.toString());
    console.log(this.SesionSVC.isAuthenticated());
    console.log(this.SesionSVC.Perfil);
    if(this.SesionSVC.isAuthenticated() && this.SesionSVC.Perfil) {
      if(!req.headers.has('Token')) _headers = _headers.append('Token',this.SesionSVC.Token.toString());
      if(!req.headers.has('AreaAcademicaId')) _headers = _headers.append('AreaAcademicaId',this.SesionSVC.Perfil.AreaAcademica.AreaAcademicaId.toString());
      if(!req.headers.has('RolId')) _headers = _headers.append('RolId',this.SesionSVC.Rol.RolId.toString());
    }
    console.log(_headers);
    const _req = req.clone({
      headers: _headers
    });

    return next.handle(_req);
  }
}
