import { Component, OnInit, HostListener } from '@angular/core';
import { PerfilService } from './shared/services/perfil.service';
import { Perfil } from './shared/DTO/Perfil';
import { Router, NavigationEnd, ActivatedRoute, Route, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { GeneralService } from './shared/services/general.service';
import { CONSTANTS } from './shared/constants';
import { LoadingService } from './services/Utils/Loading/Loading.service';
import { SesionService } from './services/Sesion/Sesion.service';
import { RolService } from './services/Rol/Rol.service';

import * as moment from 'moment';
import { CONSTANTS_SIASE } from './constants/SIASE';
import { UtilsService } from './services/Utils/Utils.service';
moment.locale("es");


declare var CONFIG: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  public perfil: Perfil;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.general_service.smallScreen = window.innerWidth < 768 ? true : false;
    this.UtilsSVC.screen.width = window.innerWidth;
    this.UtilsSVC.screen.height = window.innerHeight;
    this.UtilsSVC.screen.size = this.UtilsSVC.getScreenSize(window.innerWidth,window.innerHeight);
    this.UtilsSVC.screen.isSmall = window.innerWidth < 768 ? true : false;
    console.log(this.UtilsSVC.screen);
  }

  constructor(
    private SesionSVC: SesionService,
    private RolSVC: RolService,
    private Loading: LoadingService,
    private perfil_service: PerfilService,
    private general_service: GeneralService,
    private UtilsSVC: UtilsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.general_service.startClock();
  }

  ngOnInit() {

    CONSTANTS.API_ENDPOINT = CONFIG.get_URL_API();
    CONSTANTS.IP = CONFIG.get_URL();
    CONSTANTS.URL = CONFIG.get_URL();
    CONSTANTS.URL_SSL = CONFIG.get_URL_SSL();
    CONSTANTS.URL_SCORM = CONFIG.get_URL_SCORM();
    CONSTANTS.API_URL_SCORM = CONFIG.get_URL_API_SCORM();
    CONSTANTS.API_OFFICE = CONFIG.get_URL_OFFICE();
    CONSTANTS.API_LTI = CONFIG.get_URL_LTI();
    CONSTANTS.API_BITACORA = CONFIG.get_URL_BITACORA();
    CONSTANTS_SIASE.Portafolio = CONFIG.SIASE_Portafolio_Config();

    this.onResize(null);
    this.SesionInit();
    this.SesionInitOld();

  }

  private SesionInit() {
    console.log('Init SesionStorage');
    let _SesionStorage = this.SesionSVC.GetSesionStorage();
    console.log(_SesionStorage);
    if (_SesionStorage) {
      this.SesionSVC.SesionStorage = _SesionStorage;
      this.SesionSVC.Perfil.AreaAcademica === this.SesionSVC.Perfil.AreasAcademicas.find(AA => AA.AreaAcademicaId === this.SesionSVC.Perfil.Rol.AreaAcademicaId)
      this.SesionSVC.Rol = this.SesionSVC.Perfil.Rol;
      //this.router.navigate(["Redireccionando"]);
    }

  }
  private SesionInitOld() {
    console.log('Init Sesion Nexus');
    let PerfilSesion = this.perfil_service.getPerfilSaved();
    if (PerfilSesion) {
      this.perfil_service.InicioSesion(PerfilSesion);
    } else {
      //this.router.navigate(["Login"]);
    }
  }

  public BackdropVisible(): boolean {
    return this.Loading.BackdropVisible;
  }
  get BackdropIcon() {
    return false;
  }
  get BackdropType() {
    return this.Loading.BackdropType;
  }
  get BackdropTitle() {
    return this.Loading.Title;
  }
  get BackdropMessage() {
    return this.Loading.Message;
  }
}
