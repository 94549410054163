export class CursoImagen {
  ImagenId: number;
  CarpetaImagen: string;
  Nombre: string;
  NombreBanner: string;
  NombrePreview: string;
  Ruta: string;

  constructor(I) {
    if (I) {
      this.ImagenId = I.ImagenId;
      this.CarpetaImagen = I.CarpetaImagen;
      this.Nombre = I.Nombre;
      this.NombreBanner = I.NombreBanner;
      this.NombrePreview = I.NombrePreview;
      this.Ruta = I.Ruta;
    }
  }
}
