import { Coevaluacion } from "./Coevaluacion";
import { ExamenElemento } from "./Examen";
import { Tema } from "./Modulo";
import { TextEditor } from "./utils/TextEditor";
import { PortafolioElemento } from "./Portafolio";

export class Evidencia{
    EvidenciaId: number;
    EtapaId: number;
    Posicion: number;
    TipoEvidenciaId: number;

    Descripcion: string;
    FechaInicio;
    FechaFin;
    FechaLimite;
    Valor: number;
    EntregarDocumentos: boolean = false;
    EnEquipo: boolean = false;
    EntregaExtemporanea: boolean = false;
    EntregarRecursoExterno: boolean = false;
    UsaRubrica: boolean = false;
    UsaCoevaluacion: boolean = false;
    Contenidos: Contenido[] = [];
    EvidenciaExamenes: ExamenElemento[] = [];
    Temas: Tema[] = [];
    Tema: Tema[] = [];
    Coevaluacion: Coevaluacion;
    Justificacion: string;
    MetricaAvance;
    Estado: boolean;

    constructor(E) {
        if (E) {
            this.EvidenciaId = E.EvidenciaId;
            this.EtapaId = E.EtapaId;
            this.Posicion = E.Posicion;
            this.Descripcion = E.Descripcion;
            this.FechaInicio  = new Date(E.FechaInicio);
            this.FechaFin = new Date(E.FechaFin);
            this.FechaLimite = new Date(E.FechaLimite);
            this.TipoEvidenciaId = E.TipoEvidenciaId;
            this.Valor = E.Valor;
            this.EntregarDocumentos = E.EntregarDocumentos;
            this.EnEquipo = E.EnEquipo;
            this.EntregaExtemporanea = E.EntregaExtemporanea;
            this.EntregarRecursoExterno = E.EntregarRecursoExterno;
            this.UsaRubrica = E.UsaRubrica;
            this.UsaCoevaluacion = E.UsaCoevaluacion;
            this.Estado = E.Estado;
            this.Justificacion = E.Justificacion;
            this.MetricaAvance = E.MetricaAvance;

            if (E.EvidenciaExamenes) {
                this.EvidenciaExamenes = E.EvidenciaExamenes.map(EE => new ExamenElemento(EE));
            }

            if (E.Contenidos) {
                this.Contenidos = E.Contenidos.map(C => new Contenido(C));
            }
            if (E.Tema) {
                this.Temas = E.Tema.map(T => new TemaEvidencia(T));
                this.Tema = E.Tema.map(T => new TemaEvidencia(T));
            }
            if (E.Coevaluacion) {
                this.Coevaluacion = new Coevaluacion(E.Coevaluacion);
            }
        }
    }
}

export class Contenido{
    ContenidoEvidenciaId: number;
    EvidenciaId: number;
    Titulo: string;
    Descripcion: string;
    TipoContenidoId: number;
    Archivos = [];
    Posicion: number;
    Estado: boolean;

    constructor(C){
        if(C){
            this.ContenidoEvidenciaId = C.ContenidoEvidenciaId;
            this.EvidenciaId = C.EvidenciaId;
            this.Titulo = C.Titulo;
            this.Descripcion = new TextEditor().CleanFroalaEditor(C.Descripcion);
            this.TipoContenidoId = C.TipoContenidoId;
            this.Posicion = C.Posicion;
            this.Estado = C.Estado;

            if(C.Archivos){
                this.Archivos = C.Archivos;
            }
        }
    }
}

export class TemaEvidencia{
    EvidenciaId: number;
    TemaEvidenciaId: number;
    TemaId: number;
    Nombre: string;
    Estado: boolean;

    constructor(TE){
        if(TE){
            this.TemaEvidenciaId = TE.TemaEvidenciaId;
            this.EvidenciaId = TE.EvidenciaId;
            this.TemaId = TE.TemaId;
            this.Nombre = TE.Nombre;
            this.Estado = TE.Estado;
        }
    }
}
