import { EstudianteCurso } from "./Cuenta";


export class GrupoBase {
    GrupoId: number;
    CursoId: number;
    Nombre: string;
    EnSIASE: boolean; 
    Estado: boolean;

    constructor(G) {
        if(G != null){
            this.GrupoId = G.GrupoId;
            this.CursoId = G.CursoId;
            this.Nombre = G.Nombre;
            this.EnSIASE = G.EnSIASE;
            this.Estado = G.Estado;
        }
    }
}

export class Grupo {
    GrupoId: number;
    CursoId: number;
    Nombre: string;
    EnSIASE: boolean; 
    Estado: boolean;
    Estudiantes: EstudianteCurso[] = [];

    constructor(G) {
        if(G != null){
            this.GrupoId = G.GrupoId;
            this.CursoId = G.CursoId;
            this.Nombre = G.Nombre;
            this.EnSIASE = G.EnSIASE;
            this.Estado = G.Estado;
            if(G.Estudiantes){
                G.Estudiantes.map(E => E.GrupoId = G.GrupoId)
                this.Estudiantes = G.Estudiantes.map(E => new EstudianteCurso(E));
            }
        }
    }
}