import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import { MyMaterialModule } from './modules/material.module';
import { GeneralModule } from './modules/general/general.module';
import { SharedWidgetModule } from './modules/shared/widgets.module';

/* Locale */
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-MX';
registerLocaleData(localeEs, 'es-MX');

// import { LoginImperativeComponent as DialogLoginImperative } from './components/auth/login-imperative/login-imperative.component';
// Responder Encuesta
// import { EncuestaResponderModalComponent   as DialogEncuestaResponder} from 'src/app/components/encuestas/responder/responder-modal/responder-modal.component';

// Login
import { LoginDialogComponent     as DialogLoginComponent} from 'src/app/components/auth/login-dialog/login-dialog.component';
// Logout
import { LogoutComponent          as DialogLogoutConfirm } from 'src/app/components/auth/logout/logout.component';
// Avisos
import { AvisoVistaComponent         as DialogAvisoVista} from 'src/app/components/comunicacion/avisos/aviso-view/aviso-view.component';
// Encuestas
import { EncuestaCambiarEstadoComponent    as DialogEncuestaCambiarEstado} from 'src/app/components/encuestas/cambiar-estado/cambiar-estado.component';
// Page not Found 404
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HttpModule } from '@angular/http';
import { TokenInterceptorService } from './services/api-http/Interceptor/TokenInterceptor.service';
// import { InputEnterEventDirective } from './directives/input-enter-event.directive';
// for production
// import { HashLocationStrategy, LocationStrategy } from '@angular/common';
// { provide: LocationStrategy, useClass: HashLocationStrategy }
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { OAuthSettings } from './modules/pruebas/MicrosoftGraph/Settings';
import { PipesModule } from './modules/shared/Pipes.module';
import { AvisosModalComponent } from './components/aviso/avisos/avisos-modal/avisos-modal.component';
import { SistemaInterceptorService } from './interceptors/sistema.interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    DialogLoginComponent,
    AvisosModalComponent,
    //DialogLogin2Component,
    DialogLogoutConfirm,
    //DialogAvisosListado,
    DialogAvisoVista,
    DialogEncuestaCambiarEstado,
    //LoginDialog2Component,
    // InputEnterEventDirective,
    // PruebasComponent,
    // PageNotFoundComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    MyMaterialModule,
    SharedWidgetModule,
    PipesModule,
    // SharedModule,
    // MyMaterialModule,
    // GeneralModule,
    // AdminModule,
    // ProfesorModule,
    // EstudianteModule,
    MsalModule.forRoot({
      auth: {
        clientId: OAuthSettings.appId,
        authority: 'https://login.microsoftonline.com/' + OAuthSettings.tenant,
        postLogoutRedirectUri: OAuthSettings.LogoutRedirectUri
      },
    },
    {
      protectedResourceMap: [

      ]
    })
  ],
  entryComponents: [
    DialogLoginComponent,
    AvisosModalComponent,
    //DialogLogin2Component,
    DialogLogoutConfirm,
    //DialogAvisosListado,
    DialogAvisoVista,
    DialogEncuestaCambiarEstado
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-MX'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SistemaInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
