import { Cuenta } from "./Cuenta";
import { PersonaBase } from "./Persona";

export class Profesor extends Cuenta implements PersonaBase {
  CuentaId: number;
  Nombre: string;
  ApellidoPaterno: string;
  ApellidoMaterno: string;
  CorreoAdicional: string;
  EnSIASE: boolean;
  RolId: number;
  constructor(P) {
    super(P);
    if (P) {
      this.CuentaId = P.CuentaId;
      this.Nombre = P.Nombre;
      this.ApellidoPaterno = P.ApellidoPaterno;
      this.ApellidoMaterno = P.ApellidoMaterno;
      this.CorreoAdicional = P.CorreoAdicional;
      this.EnSIASE = P.EnSIASE;
      this.RolId = P.RolId ? P.RolId : null;
    }
  }

}
