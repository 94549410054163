import { Organizacion } from "../Organizacion/Orgaizacion";
import { Unidad } from "./Unidad";

export class Dependencia {
  DependenciaId: number;
  OrganizacionId: number;
  Nombre: string;
  NombreCorto: string;
  EsAcademica: boolean;
  EsAjenaUANL: boolean;
  Estado: boolean;
  Organizacion: Organizacion;
  Unidades: Unidad[];

  constructor(D) {
    if(D){
      this.DependenciaId = D.DependenciaId;
      this.OrganizacionId = D.OrganizacionId;
      this.Nombre = D.Nombre;
      this.NombreCorto = D.NombreCorto;
      this.EsAcademica = D.EsAcademica;
      this.EsAjenaUANL = D.EsAjenaUANL;
      this.Estado = D.Estado;
      this.Organizacion = new Organizacion(D.Organizacion);
      this.Unidades = D.Unidades.map(U => new Unidad(U));
    }
  }
}
