import { AreaAcademica } from "./AreaAcademica";
import { RolService } from "../services/rol.service";

export class Rol{

    RolId: number;
    Descripcion: string;
    Estado: number;
    EsRolPrincipal: boolean;

    constructor(R){
        if(R){
            this.RolId = R.RolId;
            this.Estado = R.Estado;
            this.Descripcion = R.Descripcion;
            this.EsRolPrincipal = R.EsRolPrincipal;
            
        }
    }
}
