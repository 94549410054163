import { Actividad } from "./Actividad";

export class Modulo{
    ModuloId: number;
    EstructuraId: number;
    Posicion: number;
    Nombre: string;
    Objetivo: string;
    Temas: Tema[] = [];
    Estado: boolean;

    constructor(M){
        if(M){
            this.ModuloId = M.ModuloId;
            this.EstructuraId = M.EstructuraId;
            this.Posicion = M.Posicion;
            this.Nombre = M.Nombre;
            this.Objetivo = M.Objetivo;
            this.Estado = M.Estado;
            if(M.Temas)
                this.Temas = M.Temas.map(T => new Tema(T));
        }
    }
}

export class Tema{
    TemaId: number;
    ModuloId: number;
    Posicion: number;
    Nombre: string;
    Actividades: Actividad[];
    Estado: boolean;

    constructor(T){
        if(T){
            this.TemaId = T.TemaId;
            this.ModuloId = T.ModuloId;
            this.Posicion = T.Posicion;
            this.Nombre = T.Nombre;
            this.Estado = T.Estado;
            if(T.Actividades)
                this.Actividades = T.Actividades.map(A => new Actividad(A));
        }
    }
}