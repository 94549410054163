import { Documento } from "./Documento";
import { TextEditor } from "./utils/TextEditor";

export class Recursos{
    RecursosArticulos: RecursoArticulo[] = [];
    RecursosExternos: RecursoExterno[] = [];
    RecursosHTML: RecursoHTML[] = [];
    RecursosLibros: RecursoLibro[] = [];
    RecursosTextos: RecursoTexto[] = [];
    RecursosWeb: RecursoWeb[] = [];
    RecursoArchivos: RecursoArchivo[] = [];
    RecursoScorm: RecursoScorm[] = [];

    constructor(R){
        if(R){
            if(R.RecursosArticulos)
                this.RecursosArticulos = R.RecursosArticulos.map(RA => new RecursoArticulo(RA));
            if(R.RecursosExternos)
                this.RecursosExternos = R.RecursosExternos.map(RE => new RecursoExterno(RE));
            if(R.RecursosHTML)
                this.RecursosHTML = R.RecursosHTML.map(RH => new RecursoHTML(RH));
            if(R.RecursosLibros)
                this.RecursosLibros = R.RecursosLibros.map(RL => new RecursoLibro(RL));
            if(R.RecursosTextos)
                this.RecursosTextos = R.RecursosTextos.map(RT => new RecursoTexto(RT));
            if(R.RecursosWeb)
                this.RecursosWeb = R.RecursosWeb.map(RW => new RecursoWeb(RW));
            if(R.RecursoArchivos)
                this.RecursoArchivos = R.RecursoArchivos.map(RA => new RecursoArchivo(RA));
            if(R.RecursoScorm)
                this.RecursoScorm = R.RecursoScorm.map(RS => new RecursoScorm(RS));
        }
    }
}


export class Recurso{
    Posicion: number;
    EsBasico: boolean;
    Estado: boolean;
    MostrarEstudiante: boolean;

    constructor(R){
        this.Posicion = R.Posicion;
        this.EsBasico = R.EsBasico;
        this.Estado = R.Estado;
        this.MostrarEstudiante = R.MostrarEstudiante;
    }
}

export class RecursoTexto extends Recurso{
    TextoId: number;
    Titulo: string;
    Contenido: string;

    constructor(RT){
        
        if(RT){
            super(RT);
            this.TextoId = RT.TextoId;
            this.Titulo = RT.Titulo;
            this.Contenido = RT.Contenido;
        }
    }
}

export class RecursoArticulo extends Recurso{
    BibliograficoArticuloId: number;
    NombrePublicacion: string;
    Titulo: string;
    Autor: string;
    LugarPublicacion: string;
    Numeracion: number;
    AnioPublicacion: number;
    MesPublicacion: number;
    DiaPublicacion: number;
    Paginas: string;
    DOI: string;
    TipoPublicacionId: number;

    constructor(RA){
        if(RA){
            super(RA);
            this.BibliograficoArticuloId = RA.BibliograficoArticuloId;
            this.NombrePublicacion = RA.NombrePublicacion;
            this.Titulo = RA.Titulo;
            this.Autor = RA.Autor;
            this.LugarPublicacion = RA.LugarPublicacion;
            this.Numeracion = RA.Numeracion;
            this.AnioPublicacion = RA.AnioPublicacion;
            this.MesPublicacion = RA.MesPublicacion;
            this.DiaPublicacion = RA.DiaPublicacion;
            this.Paginas = RA.Paginas;
            this.DOI = RA.DOI;
            this.TipoPublicacionId = RA.TipoPublicacionId;
                
        }
    }
}

export class RecursoExterno extends Recurso{
    ExternoId: number;
    Titulo: string;
    Descripcion: string;
    Contenido: string;
    VentanaEmergente: boolean;
    TipoRecursoExternoId: number;

    constructor(RE){
        if(RE){
            super(RE);
            this.ExternoId = RE.ExternoId;
            this.Titulo = RE.Titulo;
            this.Descripcion = RE.Descripcion;
            this.Contenido = RE.Contenido;
            this.VentanaEmergente = RE.VentanaEmergente;
            this.TipoRecursoExternoId = RE.TipoRecursoExternoId;
        }
    }
}

export class RecursoHTML extends Recurso{
    HTMLId: number;
    Titulo: string;
    Contenido: string;
    VentanaEmergente: boolean;

    constructor(RH){
        if(RH){
            super(RH);
            this.HTMLId = RH.HTMLId;
            this.Titulo = RH.Titulo;
            this.Contenido = new TextEditor().CleanFroalaEditor(RH.Contenido);
            this.VentanaEmergente = RH.VentanaEmergente;
        }
    }
}

export class RecursoLibro extends Recurso{
    BibliograficoLibroId: number;
    Titulo: string;
    Autor: string;
    Editorial: string;
    Edicion: string;
    AnioPublicacion: number;
    LugarPublicacion: string;
    ISBN: string;
    DOI: string;
                
    constructor(RL){
        if(RL){
            super(RL);
            this.BibliograficoLibroId = RL.BibliograficoLibroId;
            this.Titulo = RL.Titulo;
            this.Autor = RL.Autor;
            this.Editorial = RL.Editorial;
            this.Edicion = RL.Edicion;
            this.AnioPublicacion = RL.AnioPublicacion;
            this.LugarPublicacion = RL.LugarPublicacion;
            this.ISBN = RL.ISBN;
            this.DOI = RL.DOI;
        }
    }
}

export class RecursoWeb extends Recurso{
    BibliograficoWebId: number;
    Autor: string;
    NombreSitio: string;
    Titulo: string;
    URL: string;
    AnioPublicacion: number;
    MesPublicacion: number;
    DiaPublicacion: number;
    FechaConsulta;
    DOI: string;

    constructor(RW){
        if(RW){
            super(RW);
            this.BibliograficoWebId = RW.BibliograficoWebId;
            this.Autor = RW.Autor;
            this.NombreSitio = RW.NombreSitio;
            this.Titulo = RW.Titulo;
            this.URL = RW.URL;
            this.AnioPublicacion = RW.AnioPublicacion;
            this.MesPublicacion = RW.MesPublicacion;
            this.DiaPublicacion = RW.DiaPublicacion;
            this.DOI = RW.DOI;
            this.FechaConsulta = new Date(RW.FechaConsulta);
        }
    }
}

export class RecursoArchivo extends Recurso{
    DocumentoId: number;
    Titulo: string;
    Documento: Documento;
    constructor(D){
        if(D){
            super(D);
            this.DocumentoId = D.DocumentoId;
            this.Titulo = D.Documento.Nombre;
            this.Documento = new Documento(D.Documento);
        }
    }
}

export class RecursoScorm extends Recurso{
    ScormId: number;
    DocumentoId: number;
    Titulo: string;
    NombreScorm: string;
    Descripcion: string;
    Paquete: string;
    Documento: Documento;
    
    constructor(D){
        if(D){
            super(D);
            this.ScormId = D.ScormId;
            this.DocumentoId = D.DocumentoId;
            this.Titulo = D.NombreScorm;
            this.NombreScorm = D.NombreScorm;
            this.Descripcion = D.Descripcion;
            this.Paquete = D.Paquete;
            this.Documento = new Documento(D.Documento);
        }
    }
}


export class RecursoItem{
    RecursoItemId: number;
    Nombre: string;
    TipoRecursoId: number;
  
    constructor(R,ID){
      if(R){
        this.TipoRecursoId = ID;
        this.Nombre = R.Titulo || R.TituloArticulo;
        this.RecursoItemId = R.BibliograficoArticuloId || R.ExternoId || R.HTMLId || R.BibliograficoLibroId || R.TextoId || R.BibliograficoWebId || R.ScormId || R.DocumentoId;
      }
    }
}
    