import { AvisoRol } from "./AvisoRol";

export class AvisoDestino {
  AvisoDestinoId: number = 0;
  TipoDestinoId: number;
  DestinoId: number;
  Nombre: string;
  Todo: boolean = false;
  Roles: AvisoRol[] = [];
  Estado: boolean = true;

  constructor(D){
    if (D) {
      this.AvisoDestinoId = D.AvisoDestinoId;
      this.TipoDestinoId = D.TipoDestinoId;
      this.DestinoId = D.DestinoId;
      this.Nombre = D.Nombre;
      this.Todo = D.Todo;
      this.Estado = D.Estado;
      this.Roles = D.Roles.map( R => new AvisoRol(R));
    }
  }
}
