import { Component, OnInit } from '@angular/core';
import { SesionService } from 'src/app/services/Sesion/Sesion.service';
import { Router } from '@angular/router';
import { SeguridadService } from 'src/app/services/Seguridad/Seguridad.service';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private SeguridadSVC: SeguridadService,
    private SesionSVC: SesionService,
    private router: Router,
    private dialogRef: MatDialogRef<LogoutComponent>
  ) { }

  ngOnInit() {
  }

  public Logout(): void{
    this.dialogRef.close();
    this.Salir();
  }

  public Salir() {
    this.router.navigate(['Saliendo']);
  }

}
