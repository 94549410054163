import { PersonaBase, PersonaCuenta } from "./Persona";
import { CompromisoEstudiante } from "../Compromiso/CompromisoEstudiante";
import { CuentaBase } from "./Cuenta";


export class Estudiante extends PersonaBase implements CuentaBase {
  CuentaId: number;
  NombreUsuario: string;
  EnSIASE: boolean;
  CorreoUniversitario: string;
  GrupoId: number;
  constructor(E,Order?) {
    if (E) {
      super(E);
      this.NombreUsuario = E.NombreUsuario;
      this.EnSIASE = E.EnSIASE;
      this.CuentaId = E.CuentaId;
      this.CorreoUniversitario = E.CorreoUniversitario;

      this.GrupoId = E.GrupoId;
      if (Order) this.NombreCompleto(Order);
    }
  }
}

export class EstudianteCurso extends Estudiante {
  Compromiso: CompromisoEstudiante;
  constructor(E) {
    super(E);
    if (E) {
      this.Compromiso = new CompromisoEstudiante(E.Compromiso);
    }
  }
}
