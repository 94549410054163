import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private Config: MatSnackBarConfig = new MatSnackBarConfig();

  constructor(
    private snackbar: MatSnackBar,
    private zone: NgZone
  ) {
    this.Config.duration = 3000;// miliseconds
    this.Config.horizontalPosition = 'center';//'start' | 'center' | 'end' | 'left' | 'right'
    this.Config.verticalPosition = 'bottom'; // 'top' | 'bottom'
    // panelClass: string | string[]
    // announcementMessage: string
    // data: D | null
    // direction: Direction
    // viewContainerRef: ViewContainerRef
  }

  public OpenSnackBarSuccess(Msg, Time, VPosition?, HPosition?) {
    this.Config.panelClass = ["snackbar-success","action-close"];
    this.Config.duration = Time;
    this.snackbar.open(Msg, "X", this.Config);
  }
  public OpenSnackBarError(Msg, Time, VPosition?, HPosition?) {
    this.Config.panelClass = ["snackbar-error","action-close"];
    this.Config.duration = Time;
    this.snackbar.open(Msg, "X", this.Config);
  }
  public OpenSnackBarComponent(Msg, Time, VPosition?, HPosition?) {
    // openFromComponent
  }
  public OpenSnackBarTemplate(Msg, Time, VPosition?, HPosition?) {
    // openFromTemplate
  }

  public open(Msg, Time, VPosition?, HPosition?) {

    this.Config.duration = Time;
    this.Config.verticalPosition = VPosition ? VPosition : this.Config.verticalPosition;
    this.Config.horizontalPosition = HPosition ? HPosition : this.Config.horizontalPosition;
    this.snackbar.open(Msg,'',this.Config);
  }
  public dismiss() {
    this.snackbar.dismiss();
  }
  public success(Msg, Time, VPosition?, HPosition?) {
    this.Config.panelClass = ["snackbar-success","action-close"];
    this.Config.duration = Time;
    this.Config.verticalPosition = VPosition ? VPosition : this.Config.verticalPosition;
    this.Config.horizontalPosition = HPosition ? HPosition : this.Config.horizontalPosition;
    this.snackbar.open(Msg, "X", this.Config);
  }
  public error(Msg, Time, VPosition?, HPosition?) {
    this.Config.panelClass = ["snackbar-error","action-close"];
    this.Config.duration = Time;
    this.Config.verticalPosition = VPosition ? VPosition : this.Config.verticalPosition;
    this.Config.horizontalPosition = HPosition ? HPosition : this.Config.horizontalPosition;
    this.snackbar.open(Msg, "X", this.Config);
  }
  public warning(Msg, Time, VPosition?, HPosition?) {
    this.Config.panelClass = ["snackbar-warning","action-close"];
    this.Config.duration = Time;
    this.Config.verticalPosition = VPosition ? VPosition : this.Config.verticalPosition;
    this.Config.horizontalPosition = HPosition ? HPosition : this.Config.horizontalPosition;
    this.snackbar.open(Msg, "X", this.Config);
  }
  public info(Msg, Time, VPosition?, HPosition?) {
    this.Config.panelClass = ["action-close"];
    this.Config.duration = Time;
    this.Config.verticalPosition = VPosition ? VPosition : this.Config.verticalPosition;
    this.Config.horizontalPosition = HPosition ? HPosition : this.Config.horizontalPosition;
    this.snackbar.open(Msg, "X", this.Config);
  }

}
