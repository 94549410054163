export class Organizacion {
  Nombre: string;
  NombreCorto: string;
  OrganizacionId: 1;
  Estado: boolean;
  constructor(O) {
    this.Nombre = O.Nombre;
    this.NombreCorto = O.NombreCorto;
    this.OrganizacionId = O.OrganizacionId;
    this.Estado = O.Estado;
  }
}
