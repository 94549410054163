export class Modalidad{
    ModalidadId: number;
    ClaveSIASE: number;
    Nombre: string;
    Abreviatura: string;
    Estado: boolean;

    constructor(M){
        if(M){
            this.ModalidadId = M.ModalidadId;
            this.ClaveSIASE = M.ClaveSIASE;
            this.Nombre = M.Nombre;
            this.Abreviatura = M.Abreviatura;
            this.Estado = M.Estado;
            
        }
    }
}