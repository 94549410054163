import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { DialogService } from '../Utils/AngularMaterial/Dialog/Dialog.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';
import { LoginDialogComponent } from 'src/app/components/auth/login-dialog/login-dialog.component';
import { MatDialog, MatDialogRef, throwToolbarMixedModesError } from '@angular/material';

import { Sesion } from 'src/app/models/Sesion/Sesion';
import { SesionStorage } from 'src/app/models/Sesion/SesionStorage';
import { Perfil } from 'src/app/models/Persona/Perfil';
import { Rol } from 'src/app/models/Persona/Rol';
import { RolService } from 'src/app/shared/services/rol.service';
import { Router } from '@angular/router';
import { CuentaAreaAcademicaRol, CuentaAreaAcademica } from 'src/app/models/Persona/Cuenta';
import { Curso } from 'src/app/models/Curso/Curso';

@Injectable({
  providedIn: 'root'
})
export class SesionService {

  private _Sesion: Sesion;
  private _Perfil: Perfil;
  private _SesionStorage: SesionStorage
  private _Token: string;
  private DialogLogin: MatDialogRef<any>;

  private _Rol: BehaviorSubject<CuentaAreaAcademicaRol>;
  private _Curso: Curso;
  private _Roles;

  constructor (
    private RolSVC: RolService,
    private Router: Router,
    private dialog: MatDialog
  ) {
    console.log(this.GetSesionStorage());
    //this.SesionStorage = new SesionStorage(this.GetSesionStorage());
    console.log(this.SesionStorage);
    this._Rol = new BehaviorSubject<CuentaAreaAcademicaRol>(null);
  }

  get SistemaId(): number {
    return this._Sesion.SistemaId;
  }

  public isAuthenticated() {
    this.GetSesionStorage();
    if (this.GetSesionStorage()) {
      return true;
    }
    return false;
  }

  /**************** SesionStorage ***************/
  set SesionStorage(SS: SesionStorage) {
    this._SesionStorage = new SesionStorage(SS);
  }
  get SesionStorage() {
    return this._SesionStorage;
  }
  public GetSesionStorage() {
    return JSON.parse(localStorage.getItem('UanlNexus7SesionStorage'));
  }
  public SaveSesionStorage() {
    localStorage.setItem('UanlNexus7SesionStorage', JSON.stringify(this._SesionStorage));
  }
  public RemoveSesionStorage() {
    localStorage.removeItem('UanlNexus7SesionStorage');
  }

  /******************** Sesion ******************/
  public GetSesion() {
    return this._SesionStorage.Sesion;
  }
  public UpdateSesion(S: Sesion) {
    console.log(S);
    //this._SesionStorage = new SesionStorage(this.GetSesionStorage());
    this._SesionStorage.Sesion = new Sesion(S);
    this.SaveSesionStorage();
    console.log(this.GetSesionStorage());
  }

  /****************** Perfil ********************/
  set Perfil(P: Perfil) {
    this._SesionStorage.Perfil = P;
    this.SaveSesionStorage();
  }
  get Perfil(): Perfil {
    return this.GetSesionStorage().Perfil;
  }
  public ActualizarPerfil(P) {
    let CurrentRol = this._SesionStorage.Perfil.Rol;
    this._SesionStorage.Perfil = new Perfil(P);
    this._SesionStorage.Perfil.Rol = CurrentRol;
    this.SaveSesionStorage();
  }

  /****************** Rol ********************/
  public RolObserver(): Observable<CuentaAreaAcademicaRol>  {
    return this._Rol.asObservable();
  }
  get Rol(): CuentaAreaAcademicaRol {
    return this._Rol.getValue();
  }
  set Rol(R: CuentaAreaAcademicaRol) {
    this._Rol.next(R);
    this._SesionStorage.Perfil.Rol = R;
    this.SaveSesionStorage();
  }

  set Curso(C) {
    this._Curso = C;
    this._SesionStorage.Curso = C;
    this.SaveSesionStorage();
  }
  get Curso() {
    return this._SesionStorage.Curso;
  }


  /******************** Token *******************/
  set Token(Token: string) {
    //this._Sesion.Token(Token);
    this._SesionStorage.Sesion.Token = Token;
    this.SaveSesionStorage();
  }
  get Token(): string {
    return this.GetSesionStorage().Sesion.Token;
  }
  public isTokenExpirado(Code) {
    if (Code == 2004 || Code == 2011) {
      return true;
    } else {
      return false;
    }
  }

  public Roles(AreaAcademicaId?) {
    let _RolesTotal : CuentaAreaAcademicaRol[] = [];
    let _AreasAcademicas: CuentaAreaAcademica[] = !AreaAcademicaId ? this.Perfil.AreasAcademicas : this.Perfil.AreasAcademicas.filter(AA => AA.AreaAcademicaId === AreaAcademicaId);
    console.log(_AreasAcademicas);
    for (var i in _AreasAcademicas) {
        let _Roles = _AreasAcademicas[i].Roles;
        for(var j in _Roles) {
            console.log(_Roles[j]);
            _Roles[j].Descripcion = this.RolSVC.getRol(_Roles[j].RolId).Descripcion;
            _Roles[j].AreaAcademicaId = _AreasAcademicas[i].AreaAcademicaId;
            if(_Roles[j].RolId === 1) {
              // - ('+_AreasAcademicas[i].AreaAcademica.Nombre+')
              //_Roles[j].Descripcion = 'Administrador';
              //_RolesTotal.push(_Roles[j]);
            }
            if(!_RolesTotal.find(R => R.RolId === _Roles[j].RolId)) {
              _RolesTotal.push(_Roles[j]);
            }
        }
    }
    console.log(_RolesTotal);
    return _RolesTotal;
  }

  public ObtenerAreasAcademicas(RolId) {
    console.log(this.Perfil);
    let _AreasAcademicas: CuentaAreaAcademica[] = [];
    this.Perfil.AreasAcademicas.forEach(AA => {
      if(AA.Roles.find(R => R.RolId === RolId)) {
        _AreasAcademicas.push(AA);
      }
    });
    console.log(_AreasAcademicas);
    return _AreasAcademicas;
  }

  //Verifica el Rol y redirecciona a la seccion corrspondiente
  public RedireccionarPerfil(){
    let _RolPrincipal = this._SesionStorage.Perfil.Rol.RolId;

    //AdminGral
    if(_RolPrincipal == 6) this.Router.navigate(["AdminGral"]);
    //Auditor
    if(_RolPrincipal == 12) this.Router.navigate(["Auditor"]);
    //DENMS
    if(_RolPrincipal == 13) this.Router.navigate(["Seguimiento"]);
    //Seguimiento Académico
    if(_RolPrincipal == 14) this.Router.navigate(["Seguimiento"]);
    //Monitoreo
    if(_RolPrincipal == 17) this.Router.navigate(["Desempeno"]);
    //Administrador
    if(_RolPrincipal == 1) this.Router.navigate(["Administrador"]);
    //Administrador Asistente
    if(_RolPrincipal == 10) this.Router.navigate(["Administrador"]);
    //Profesor
    if(_RolPrincipal == 2) this.Router.navigate(["Profesor"]);
    //Estudiante
    if(_RolPrincipal == 5) this.Router.navigate(["Estudiante"]);
  }

  public RolHasPermission(Roles) {
    return Roles.includes(this.Perfil.Rol.RolId);
  }

  public DialogSesionExpirada(): Observable<any> {
    console.log("SesionSVC: Dialog Sesion Expirada");
    let Sesion;
    if (this.DialogLogin) this.DialogLogin.close();
    this.DialogLogin = this.dialog.open(LoginDialogComponent,{disableClose: true});
    //return this.DialogLogin.afterClosed();
    return this.DialogLogin.afterClosed().pipe(
      map(data => console.log('After Closed'),
      catchError(error => throwError(error))
    ));
  }

  get isCEV(): boolean {
    return this._SesionStorage.Perfil.AreaAcademica.AreaAcademicaId === 5 ? true : false;
  }

}
