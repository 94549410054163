import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';
import { RequestOptions, ResponseContentType } from '@angular/http';
import { CONSTANTS } from 'src/app/shared/constants';

import { HttpClient, HttpHeaders, HttpEvent, HttpErrorResponse, HttpResponse, HttpEventType, HttpBackend} from '@angular/common/http';
import { PerfilService } from 'src/app/shared/services/perfil.service';
import { SnackBarService } from '../Utils/AngularMaterial/SnackBar/SnackBar.service';
import { SesionService } from '../Sesion/Sesion.service';

@Injectable({
  providedIn: 'root'
})

export class ApiHttpService {

  private HttpOptions = {};

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
    private PerfilSVC: PerfilService,
    private SesionSVC: SesionService,
    private Snackbar: SnackBarService
  ) { }

  public GetHttpOptions(_Headers?, ReportProgress?, Observe?) {
    let _HttpHeaders: HttpHeaders;
    if (_Headers) {
      _HttpHeaders = new HttpHeaders(_Headers);
    } else {
      _HttpHeaders = new HttpHeaders({
        Token: this.SesionSVC.Token,
        AreaAcademicaId: this.SesionSVC.Perfil.AreaAcademica.AreaAcademicaId.toString(),
        RolId: this.SesionSVC.Perfil.Rol.RolId.toString(),
      });
      _HttpHeaders.append("SistemaId",this.SesionSVC.SesionStorage.Sesion.SistemaId.toString());
    }

    let _HttpOptions = {
      headers: _HttpHeaders,
    }
    console.log(_HttpHeaders);

    return { headers: _HttpHeaders};
  }

  public post(_domain, _method, _data, _reportProgress?, _headers?) {
    return this.http.post(
      this.GetUrlApiEndpoint(_domain, _method),
      _data,
      this.GetHttpOptions(_headers),
      /*{
        headers: this.GetHttpOptions(_headers),
        reportProgress: _reportProgress ? _reportProgress : false,
        observe: 'events'
      }*/
    );
  }

  public PROMISE() {

  }
  public REQUEST_UPLOAD(Domain, Method, Data, Headers?, ReportProgress?, Observe?) {
    return this.http.post(
      this.GetUrlApiEndpoint(Domain, Method),
      Data,
      {
        headers: this.GetHttpOptions(Headers,ReportProgress,Observe).headers,
        reportProgress: true,
        observe: 'events'
      }
    ).pipe(
      map(response => this.EventsCallback(response)),
      catchError(error => this.ErrorCallback(error))
    );
  }
  public POST_NO_INTERCEPTORS(Domain, Method, Data, Headers?) {
    let httpClient = new HttpClient(this.handler);
    return httpClient.post(
      this.GetUrlApiEndpoint(Domain, Method),
      Data,
      this.GetHttpOptions(Headers)
    ).pipe(
      map(response => this.SuccessCallback(response)),
      catchError(error => this.ErrorCallback(error))
    );
  }

  public POST(Domain, Method, Data, Headers?) {
    console.log(JSON.stringify(Data));
    console.log(Headers);
    return this.http.post(
      this.GetUrlApiEndpoint(Domain, Method),
      Data,
      this.GetHttpOptions(Headers)
    ).pipe(
      map(response => this.SuccessCallback(response)),
      catchError(error => this.ErrorCallback(error))
    );
  }
  public GET(Domain, Method, Headers?) {
    return this.http.get(
      this.GetUrlApiEndpoint(Domain, Method),
      this.GetHttpOptions(Headers)
    ).pipe(
      map(response => this.SuccessCallback(response)),
      catchError(error => this.ErrorCallback(error))
    );
  }
  public GetUrlApiEndpoint(Domain, Method) {
    let URL = CONSTANTS.API_ENDPOINT + Domain + '/' + Method;
    return URL;
  }

  public SuccessCallback(response: any) {
    response.Error = response.hasOwnProperty('Code') ? true : false;
    response.TokenExpired = this.SesionSVC.isTokenExpirado(response.Code) ? true : false;
    if (response.Error) this.Snackbar.OpenSnackBarError(`${response.Code} - ${response.Message}`, 3000);
    //if (response.TokenExpired) this.Snackbar.OpenSnackBarError(`Tu sesion actual ha expirado.`, 3000);
    console.log(response);
    return response;
  }
  public EventsCallback(event: HttpEvent<any>) {
    switch(event.type) {
      case  HttpEventType.Sent:
            console.log('Start Upload');
            break;
      case  HttpEventType.UploadProgress:
            return {
              complete: false,
              uploading: true,
              upload: 'Cargando '+Math.round(100 * event.loaded / event.total)
            }
      case  HttpEventType.Response:
            let response = this.SuccessCallback(event.body);
            response.complete = true;
            response.uploading = false;
            response.upload = 100;
            return response;
      default:
            console.log('Uploading Event Unknow');
            break;
    }
  }
  public ErrorCallback(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }
}
