export class AvisoCuenta {
  AvisoDestinoRolCuentaId: number = 0;
  AvisoDestinoRolId: number;
  CuentaId: number;
  Estado: boolean = true;

  constructor(C) {
    if (C) {
      this.AvisoDestinoRolCuentaId = this.AvisoDestinoRolCuentaId;
      this.AvisoDestinoRolId = C.AvisoDestinoRolId;
      this.CuentaId = C.CuentaId;
      this.Estado = C.Estado;
    }
  }
}
