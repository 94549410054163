import { TextEditor } from "./utils/TextEditor";

export class PIA{

    ProductoIntegradorId: number;
    EstructuraId: number;


    Descripcion: string;
    FechaInicio;
    FechaFin;
    FechaLimite
    Valor: number;
    EntregarDocumentos: boolean = false;
    EnEquipo: boolean = false;
    EntregaExtemporanea: boolean = false;
    EntregarRecursoExterno: boolean = false;
    UsaRubrica: boolean = false;
    Contenidos: Contenido[] = [];
    Estado: boolean;

    constructor(P){
        if(P){
            this.ProductoIntegradorId = P.ProductoIntegradorId;
            this.EstructuraId = P.EstructuraId;

            this.Descripcion = P.Descripcion;
            this.FechaInicio  = new Date(P.FechaInicio);
            this.FechaFin = new Date(P.FechaFin);
            this.FechaLimite = new Date(P.FechaLimite)
            this.Valor = P.Valor;
            this.EntregarDocumentos = P.EntregarDocumentos;
            this.EnEquipo = P.EnEquipo;
            this.EntregaExtemporanea = P.EntregaExtemporanea;
            this.EntregarRecursoExterno = P.EntregarRecursoExterno;
            this.UsaRubrica = P.UsaRubrica;

            this.Estado = P.Estado;
            if (P.Contenidos) {
              this.Contenidos = P.Contenidos.map(C => new Contenido(C));
            }
        }
    }
}

export class Contenido{
    ContenidoProductoIntegradorId: number;
    ProductoIntegradorId: number;
    Titulo: string;
    Descripcion: string;
    TipoContenidoId: number;
    Archivos = [];
    Posicion: number;
    Estado: boolean;

    constructor(C){
        if(C){
            this.ContenidoProductoIntegradorId = C.ContenidoProductoIntegradorId;
            this.ProductoIntegradorId = C.ProductoIntegradorId;
            this.Titulo = C.Titulo;
            this.Descripcion = new TextEditor().CleanFroalaEditor(C.Descripcion);
            this.TipoContenidoId = C.TipoContenidoId;
            this.Posicion = C.Posicion;
            this.Estado = C.Estado;

            if(C.Archivos){
                this.Archivos = C.Archivos;
            }
        }
    }
}
