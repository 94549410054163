import { NgModule } from '@angular/core';


/* Material Speed Dial Menu https://github.com/Ecodev/fab-speed-dial */
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
/* Ng-Select */
import { NgSelectModule } from '@ng-select/ng-select';
/* DateTimePicker https://www.npmjs.com/package/angular2-datetimepicker */
import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
// Moment
import { MomentModule } from 'angular2-moment';
//Files Drop https://georgipeltekov.github.io/
import { FileDropModule } from 'ngx-file-drop';
// Ngx Editor
import { NgxEditorModule } from 'ngx-editor';
// Ngx Color Picker
import { ColorPickerModule} from 'ngx-color-picker';

// Ngx Order
import { OrderModule } from 'ngx-order-pipe';
// Ngx Filter
import { FilterPipeModule } from 'ngx-filter-pipe';
// NgxPipes https://www.npmjs.com/package/ngx-pipes#installation
import {NgPipesModule} from 'ngx-pipes';

//Ng-click-outside
import { ClickOutsideModule } from 'ng-click-outside';
//Chart JS
import { ChartsModule } from 'ng2-charts/ng2-charts';
//Froala EDITOR
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
//Angular Calendar
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
//Angular Drag and Drop elements
import { NgDragDropModule } from 'ng-drag-drop';
// NgxSortable
import { NgxSortableModule } from 'ngx-sortable';
// Ngx Countdown
import { CountdownModule } from 'ngx-countdown';
// ng6-breadcrumbs
//import {BreadcrumbsModule} from "ng6-breadcrumbs";
// ngx-doc-viewer
import {NgxDocViewerModule} from 'ngx-doc-viewer';
// DigitOnly Input Text
import { DigitOnlyModule } from '@uiowa/digit-only';
// Ngx Export As
//import { ExportAsModule } from 'ngx-export-as';

//Ngx Scrollbar
//import { NgScrollbarModule } from 'ngx-scrollbar';

//Angular Progress Bar
import {ProgressBarModule} from "angular-progress-bar";

//Angular Breadcrumbs
import { BreadcrumbModule } from 'angular-crumbs';
//import { BreadcrumbsModule } from "ng2-breadcrumbs";

//Angular Material File Input
//import { MaterialFileInputModule } from 'ngx-material-file-input';

// Ngx Drag Drop https://www.npmjs.com/package/ngx-drag-drop
import { DndModule } from 'ngx-drag-drop';

// https://www.npmjs.com/package/ngx-scroll-event
import { ScrollEventModule } from 'ngx-scroll-event';

// https://www.npmjs.com/package/ngx-mat-select-search
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


// Pipes
import { SafeHTMLPipe } from 'src/app/pipes/safe-html.pipe';
import { UniquePipe } from 'src/app/pipes/unique.pipe';
import { SafeURLPipe } from 'src/app/pipes/safe-url';
import { TimerComponent } from 'src/app/components/ui/timer/timer.component';
import { PipesModule } from './Pipes.module';

const _declarations = [];
const _imports = [];
const _exports = [];

@NgModule({
  declarations: [
    //SafeHTMLPipe,
    //SafeURLPipe,
    //UniquePipe,
    TimerComponent,
  ],
  imports: [
    EcoFabSpeedDialModule,
    AngularDateTimePickerModule,
    MomentModule,
    FileDropModule,
    NgxEditorModule,
    ColorPickerModule,
    NgPipesModule,
    OrderModule,
    FilterPipeModule,
    ClickOutsideModule,
    ChartsModule,
    FroalaEditorModule,
    FroalaViewModule,
    NgxSortableModule,
    CountdownModule,
    // BreadcrumbsModule,
    NgxDocViewerModule,
    DigitOnlyModule,
    // ExportAsModule,
    NgDragDropModule,
    CalendarModule.forRoot({provide: DateAdapter, useFactory: adapterFactory}),
    BreadcrumbModule,
    ProgressBarModule,
    // MaterialFileInputModule,
    DndModule,
    ScrollEventModule,
    NgxMatSelectSearchModule,
    PipesModule
  ],
  exports: [
    //SafeHTMLPipe,
    //SafeURLPipe,
    //UniquePipe,
    EcoFabSpeedDialModule,
    AngularDateTimePickerModule,
    MomentModule,
    FileDropModule,
    NgxEditorModule,
    ColorPickerModule,
    NgPipesModule,
    OrderModule,
    FilterPipeModule,
    ClickOutsideModule,
    ChartsModule,
    FroalaEditorModule,
    FroalaViewModule,
    NgxSortableModule,
    CountdownModule,
    // BreadcrumbsModule,
    NgxDocViewerModule,
    DigitOnlyModule,
    // ExportAsModule,
    NgDragDropModule,
    CalendarModule,
    BreadcrumbModule,
    ProgressBarModule,
    // MaterialFileInputModule,
    DndModule,
    ScrollEventModule,
    NgxMatSelectSearchModule
  ]
})

export class SharedWidgetModule { }
