import { CuentaGeneral } from "./Cuenta";

export class Foro{
    ForoId: number;
    CursoId: number;
    Nombre: string;
    Estado: boolean;
    
    constructor(F){
        if(F){
            this.ForoId = F.ForoId;
            this.CursoId = F.CursoId;
            this.Nombre = F.Nombre;
            this.Estado = F.Estado;
        }
    }
}

export class ForoTema{
    TemaId: number;
    ForoId: number;
    TipoTemaId: number;

    Nombre: string;
    Mensaje: string;
    FechaInicio;
    FechaFin;

    PorEquipo: boolean = false;
    EditarComentarios: boolean = false;
    NotificarComentarios: boolean = false;
    VerComentariosGrupos: boolean = false;
    CerrarConCurso: boolean = false;

    Equipos = [];
    Estado: boolean;

    constructor(T){
        if(T){
            this.TemaId = T.TemaId;
            this.ForoId = T.ForoId;
            this.TipoTemaId = T.TipoTemaId;

            this.Nombre = T.Nombre;
            this.Mensaje = T.Mensaje;
            this.FechaInicio = new Date(T.FechaInicio);
            this.FechaFin = new Date(T.FechaFin);

            this.PorEquipo = T.PorEquipo;
            this.EditarComentarios = T.EditarComentarios;
            this.NotificarComentarios = T.NotificarComentarios;
            this.VerComentariosGrupos = T.VerComentariosGrupos;
            this.CerrarConCurso = T.CerrarConCurso;
            this.Equipos = T.Equipos;
            this.Estado = T.Estado;
        }
    }
}

export class TemaComentario{
    TemaComentarioId: number;
    TemaId: number;
    ComentarioPadreId: number;
    Comentario: string;
    ComentariosHijos: TemaComentario[] = [];
    Respuestas: TemaComentario[] = [];
    Marcado: boolean;
    Destacado: boolean;
    EstadoComentarioId: number;
    EsRetroalimentacion: boolean;
    Cuenta: CuentaGeneral;
    FechaCreacion;
    Oculto: boolean;
    Estado: boolean;

    constructor(C){
        if(C){
            this.TemaComentarioId = C.TemaComentarioId;
            this.TemaId = C.TemaId;
            this.ComentarioPadreId = C.ComentarioPadreId;
            this.Comentario = C.Comentario;
            this.ComentariosHijos = C.ComentariosHijos ? C.ComentariosHijos.map(C => new TemaComentario(C)) : [];
            this.Respuestas = C.Respuestas ? C.Respuestas.map(C => new TemaComentario(C)) : [];
            this.Marcado = C.Marcado;
            this.Destacado = C.Destacado;
            this.EstadoComentarioId = C.EstadoComentarioId;
            this.EsRetroalimentacion = C.EsRetroalimentacion;
            this.Cuenta = new CuentaGeneral(C.Cuenta,null);
            this.FechaCreacion = new Date(C.FechaCreacion);
            this.Oculto = C.Oculto;
            this.Estado = C.Estado;
        }
    }
}

export class ForoTemaComentarios extends ForoTema{
    Comentarios : TemaComentario[];

    constructor(T){
        super(T);
        if(T){
            if(T.Comentarios){
                this.Comentarios = T.Comentarios.map(C => new TemaComentario(C));
            }
        }
    }
}